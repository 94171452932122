<template>
  <div :id="active ? 'green' : 'red'">
    <v-text-field
      v-model="textModel"
      :disabled="disabled"
      :placeholder="placeholder"
      solo
      :type="type"
    >
      <template v-slot:append>
        <v-icon v-if="active" color="#3fab36" size="18" class="font-weigth-400"
          >fas fa-check</v-icon
        >
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    model: String,
    color: String,
    primary: Boolean,
    active: Boolean,
    disabled: Boolean,
    placeholder: String,
    type: String,
    name: String
  },

  watch: {
    textModel(oldVal, newVal) {
      this.$emit("validate", { name: this.name, value: oldVal });
    }
  },
  data() {
    return {
      textModel: this.model
    };
  }
};
</script>
<style scoped lang="scss">
#green::v-deep {
  .v-input__control .v-input__slot {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    padding-left: 16px;
    border: 1px solid #e9e5e5;

    input {
      font-size: 16px;
      font-weight: 300;
      color: green;
    }
  }

  .v-text-field__details {
    display: none;
  }
}

#red::v-deep {
  .v-input__control .v-input__slot {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    padding-left: 16px;
    border: 1px solid #e9e5e5;

    input {
      font-size: 16px;
      font-weight: 300;
    }
  }

  .v-text-field__details {
    display: none;
  }
}
</style>
