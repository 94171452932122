<template>
  <v-card id="LanguageSelector" class="Ecotrace-card">
    <v-btn
      absolute
      right
      fab
      small
      depressed
      color="white"
      class="close-btn secondary--text"
      @click="close"
    >
      <v-icon>fas fa-times</v-icon>
    </v-btn>
    <v-card-text class="px-8 pt-12">
      <v-row class="mb-2">
        <v-btn elevation="0" class="pa-2 ma-2" @click="selectLang('pt-br')">
          <v-img
            src="@/assets/images/bandeira-brasil.svg"
            max-width="40"
            max-height="40"
          />
          <div class="ml-2">{{ this.texts.lingua_pt_br }}</div>
        </v-btn>
      </v-row>
      <v-row class="mb-2">
        <v-btn elevation="0" class="pa-2 ma-2" @click="selectLang('en')">
          <v-img
            src="@/assets/images/bandeira-inglaterra.svg"
            max-width="40"
            max-height="40"
            contain
          />
          <div class="ml-2">{{ this.texts.lingua_en }}</div>
        </v-btn>
      </v-row>
      <v-row class="mb-2">
        <v-btn elevation="0" class="pa-2 ma-2" @click="selectLang('es')">
          <v-img
            src="@/assets/images/bandeira-espanha.svg"
            max-width="40"
            max-height="40"
            contain
          />
          <div class="ml-2">{{ this.texts.lingua_es }}</div>
        </v-btn>
      </v-row>
      <v-row class="mb-2">
        <v-btn elevation="0" class="pa-2 ma-2" @click="selectLang('cn')">
          <v-img
            src="@/assets/images/bandeira-china.svg"
            max-width="40"
            max-height="40"
            contain
          />
          <div class="ml-2">{{ this.texts.lingua_cn }}</div>
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'

export default {
  props: ['texts'],
  methods: {
    close() {
      this.$store.state.blockchain = false
      this.$store.state.home = true
      this.$store.state.headerTitle = this.texts.dados_produto
    },
    selectLang(lang) {
      this.$store.state.languageSelector = false
      this.$store.state.home = true
      this.$store.state.headerTitle = this.texts.dados_produto
      Vue.set(this.$store.state, 'lang', lang)
    }
  }
}
</script>

<style lang="scss" scoped>
#BlockChainPage {
  .close-btn {
    top: 22px;
    right: 22px;
  }
}
</style>
