<template>
  <v-timeline id="Ecotrace-timeline" align-top dense>
    <v-timeline-item id="info-origem" fill-dot color="white">
      <template v-slot:icon>
        <v-img
          contain
          max-width="20"
          max-height="20"
          src="@/assets/icons/origem.png"
        ></v-img>
      </template>
      <h2 class="title text-uppercase primary--text mt-1 ml-2">
        {{ texts.info_origem }}
      </h2>
      <ExpandableBiomes
        v-if="shouldDisplayBiome('AMAZÔNIA')"
        :biomaName="'Amazônia'"
        :biomaMapImage="require('@/assets/icons/amazonia.png')"
        :bgColor="'#62B2401A'"
        :textColor="'#62B240'"
        :track="filterArrayBiomaAndReturnObjectUpdated('AMAZÔNIA')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('CERRADO')"
        :biomaName="'Cerrado'"
        :biomaMapImage="require('@/assets/icons/cerrado.png')"
        :bgColor="'#FF98191A'"
        :textColor="'#FF981A'"
        :track="filterArrayBiomaAndReturnObjectUpdated('CERRADO')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('CAATINGA')"
        :biomaName="'Caatinga'"
        :biomaMapImage="require('@/assets/icons/caatinga.png')"
        :bgColor="'#FFCF311A'"
        :textColor="'#FFCF31'"
        :track="filterArrayBiomaAndReturnObjectUpdated('CAATINGA')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('MATA ATLÂNTICA')"
        :biomaName="'Mata Atlântica'"
        :biomaMapImage="require('@/assets/icons/mata-atlantica.png')"
        :bgColor="'#006E071A'"
        :textColor="'#005304'"
        :track="filterArrayBiomaAndReturnObjectUpdated('MATA ATLÂNTICA')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('PANTANAL')"
        :biomaName="'Pantanal'"
        :biomaMapImage="require('@/assets/icons/pantanal.png')"
        :bgColor="'#01B5911A'"
        :textColor="'#01B591'"
        :track="filterArrayBiomaAndReturnObjectUpdated('PANTANAL')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('PAMPA')"
        :biomaName="'Pampa'"
        :biomaMapImage="require('@/assets/icons/pampa.png')"
        :bgColor="'#00B7C31A'"
        :textColor="'#00CEC8'"
        :track="filterArrayBiomaAndReturnObjectUpdated('PAMPA')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('SEM BIOMA')"
        :biomaName="'Bioma não informado'"
        :biomaMapImage="require('@/assets/icons/error-bioma.png')"
        :bgColor="'#7070701A'"
        :textColor="'#707070'"
        :track="filterArrayBiomaAndReturnObjectUpdated('SEM BIOMA')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />
    </v-timeline-item>
    <v-timeline-item id="info-industria" fill-dot color="white" right>
      <template v-slot:icon>
        <v-img
          contain
          max-width="18"
          max-height="18"
          src="@/assets/icons/industria.png"
        ></v-img>
      </template>
      <h2 class="title text-uppercase primary--text mt-1 ml-2">
        {{ texts.info_industria }}
      </h2>
      <InfoIndustria
        :texts="texts"
        :track="track"
        :trackDestino="trackDestino"
        :trackOrigem="trackOrigem"
      />
    </v-timeline-item>
    <v-timeline-item id="info-varejo" fill-dot color="white">
      <template v-slot:icon>
        <v-icon size="16" color="secondary">far fa-comments</v-icon>
      </template>
      <h2 class="title text-uppercase primary--text mt-1 ml-2">
        {{ texts.info_varejo }}
      </h2>
      <InfoVarejo :texts="texts" :track="track" :trackOrigem="trackOrigem" />
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import InfoVarejo from '@/components/blocks/InfoVarejo'
import ExpandableBiomes from '@/components/ExpandableBiomes'
import InfoIndustria from '@/components/blocks/InfoIndustria'

export default {
  props: ['texts', 'track', 'trackOrigem', 'trackDestino'],
  components: {
    InfoIndustria,
    InfoVarejo,
    ExpandableBiomes
  },

  computed: {
    shouldDisplayBiome() {
      return (biomeName) => {
        return (
          this.filterArrayBiomaAndReturnObjectUpdated(biomeName).environmental
            .length > 0
        )
      }
    },

    filterArrayBiomaAndReturnObjectUpdated() {
      return (biomaName) => {
        const biomaPadrao = '(SEM BIOMA|PROPRIEDADE SEM BIOMA)'

        const arrayFiltrado = this.trackOrigem.environmental
          .map((item) => ({
            ...item,
            bioma: item.bioma || biomaPadrao
          }))
          .filter((item) => this.extractBioma(item.bioma) === biomaName)

        return {
          ...this.trackOrigem,
          environmental: arrayFiltrado
        }
      }
    }
  },

  methods: {
    extractBioma(biomaName) {
      const matches = biomaName.match(/\(([^|]+)\|/)
      return matches ? matches[1] : null
    }
  }
}
</script>

<style lang="scss" scoped>
#Ecotrace-timeline::v-deep {
  padding: 0;
  margin-top: 24px;

  &:before {
    background: rgb(63, 171, 54);
    background: linear-gradient(
      180deg,
      rgba(63, 171, 54, 1) 0%,
      rgba(224, 226, 232, 1) 98%
    );
    max-height: 97%;
    width: 3px;
  }

  .v-timeline-item {
    justify-content: center;

    .v-timeline-item__divider {
      min-width: 46px;

      .v-timeline-item__dot {
        box-shadow: none;

        .v-timeline-item__inner-dot {
          border: 1px solid $primary-color;
          border-color: $primary-color !important;
        }
      }
    }

    /* .v-timeline-item__body {
      margin-left: 5px;
    } */
  }
}
</style>
