export default {
  inicio: 'Start',
  origem: 'Origin',
  industria: 'Industry',
  dt_producao: 'Production Date',
  dt_validade: 'Expiration Date',
  dt_recebimento: 'Receiving Date',
  codigo_rastreio: 'Tracking Code',
  tipo_corte: 'Cut Type',
  lote_producao: 'Production Batch',
  nome: 'Name',
  cnpj: 'CNPJ (National Register of Legal Entities)',
  email: 'Email',
  telefone: 'Phone',
  fazenda: 'Farm',
  cidade: 'City',
  historico_produto: 'Product History',
  dados_produto: 'Product Data',
  info_origem: 'Origin Information - Biomes',
  ver_mapa: 'View on Map',
  analise_ambiental: 'Environmental Analysis',
  certificados: 'Certificates',
  blockchain: 'Blockchain',
  contato: 'Contact',
  selo_ecotrace: 'Ecotrace Seal',
  info_industria: 'Industry Information',
  dados_unidade_produtora: 'Producer Unit Data',
  localizacao_unidade_produtora: 'Producer Unit Location',
  certificados_unidade_produtora: 'Producer Unit Certificates',
  planta_habilitada_exportacao: 'Export Enabled Plant',
  ver_lista_completa: 'View Full List',
  ver_lista_reduzida: 'View Short List',
  info_varejo: 'Virtual Assistant',
  botao_chatbot: 'Would you like to speak to our virtual assistant?',
  localizacao_varejo: 'Retail Location',
  ajuda_sobre_produto: 'Need help with this product?',
  fale_com_barcellos: 'Contact Barcellos',
  fazer_pergunta: 'Ask a Question',
  propriedade_conforme: '100% SUITABLE property!',
  nada_consta: 'No Records Found',
  certificados_title: 'SIF - ESTABLISHMENT REGISTRATION',
  certificados_text:
    'Establishment registration with the Federal Inspection Service - SIF is mandatory for all animal product industries: meat and derivatives, milk and derivatives, fish, honey and eggs; that intend to sell their products outside state or federal borders.',
  blockchain_title: 'Data Protected by Blockchain',
  blockchain_text:
    'Blockchain is a distributed ledger technology that aims for decentralization as a security measure. They are distributed and shared record and data bases that have the function of creating a global index for all transactions that occur in a given market.',
  seloecotrace_title: 'Data Protected by Blockchain',
  seloecotrace_text:
    'Blockchain is a distributed ledger technology that aims for decentralization as a security measure. They are distributed and shared data and record bases that have the function of creating a global index for all transactions that occur in a particular market.',
  deixe_mensagem: 'Leave your message',
  mensagem_header_text:
    'If you identify any problem with this product or have any questions, please let us know. We will respond as soon as possible.',
  enviar_minha_duvida: 'Send my question',
  produto_rastreabilidade: 'Product with production traceability',
  consulta_dados_historico_ecotrace:
    'Now you can consult all production history data for this product with Technology',
  dados_disponiveis_breve: 'The data will be available soon',
  rastreabilidade_da_producao: 'Production traceability',
  dados_disponiveis_em:
    'The data for this product is being processed and will be available in',
  produto_frigol: 'Frigol product',

  propriedade_nao_conforme: 'Property not suitable!',
  analise_status_conforme: 'Conformant',
  analise_status_nao_conforme: 'Non-conformant',
  propriedade_com_inconsistencia: 'Property with inconsistency!',

  id_monitoramento: 'Monitoring ID',
  analise_ambiental_title: 'Environmental Analysis',
  empresa_responsavel_monitoramento: 'Company responsible for monitoring',

  Desmatamento: 'Deforestation',
  'Trabalho escravo': 'Slave labor',
  'Invasão de Terras Indígenas': 'Invasion of Indigenous Lands',
  'Embargo IBAMA': 'IBAMA status',
  'Invasão de Unidade de Conservação Ambiental':
    'Invasion of Environmental Conservation Unit',

  nao_encontrado_title: 'We need some information',
  informacao_nao_econtrada: 'Information not found',
  ajuda: 'If you need help, please contact us via email',
  como_acessar:
    'To access all product information, provide the data that appears on the packaging.',
  ver_dados_do_produto: 'View Product Data',
  languageSelector: 'Select Language',
  lingua_pt_br: 'Portuguese',
  lingua_en: 'English',
  lingua_es: 'Spanish',
  lingua_cn: 'Chinese'
}
