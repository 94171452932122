<template>
  <v-card class="info-text-card">
    <v-card-text class="d-flex pa-0">
      <v-col cols="2" class="icon-container d-flex justify-center align-center">
        <v-icon color="secondary" size="29">
         far fa-question-circle
        </v-icon>
      </v-col>
      <v-col cols="10" class="text-container pa-3">
        <p class="pl-3 ma-0" style="font-size: 16px; line-height: 22px;">
          {{ text }}
          <b v-if="extraText" class="primary--text font-weight-medium">{{ extraText }}</b>
        </p>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["text", "extraText"],

}
</script>

<style lang="scss" scoped>
  .info-text-card::v-deep {
    border-radius: 10px !important;
    box-shadow: 0px 3px 0px #0000000A;

    .icon-container {
      border-radius: 10px 0 0 10px;
      background-color: #FFFFFF;
    }

    .text-container {
      border-radius: 0 10px 10px 0;
      background-color: #F4F8F7;

      p {
        color: #545557;
      }
    }
  }
</style>