<template>
  <div class="container-infos">
    <v-row>
      <v-col class="d-flex justify-space-between align-center">
        <div class="infos">
          <p>{{ trackOrigem[idx].faz_nome.toLowerCase() }}</p>
          <span>
            {{ trackOrigem[idx].faz_cidade.toLowerCase() }} -
            {{ trackOrigem[idx].faz_estado }}
          </span>
          <div
            class="id-monitoramento"
            v-if="trackOrigem[idx].cod_empresa_ambiental"
          >
            ID Monitoramento:
            <span class="id" ref="noreferrer; noopener" target="_blank">
              {{ trackOrigem[idx].cod_empresa_ambiental }}
            </span>
          </div>
          <span class="laudo" v-if="trackOrigem[idx].cod_empresa_ambiental">
            Empresa responsável pelo monitoramento:
            <a
              class="url-laudo"
              :href="nicePlanetURL"
              ref="noreferrer; noopener"
              target="_blank"
            >
              {{ nicePlanetURL }}
            </a>
          </span>
        </div>
        <v-img
          src="@/assets/icons/selo_ambiental.png"
          max-width="34"
          max-height="47"
        ></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters class="btns-container my-5">
      <v-col cols="6">
        <v-btn rounded outlined color="primary" @click="showMap(idx)">
          <v-icon left color="primary" size="13">fas fa-map-marker-alt</v-icon>
          <span class="quaternary--text">{{ texts.ver_mapa }}</span>
        </v-btn>
      </v-col>
      <v-col cols="6" class="text-center">
        <v-btn rounded depressed dark color="success" @click="showAmbiental">
          <v-icon left size="13">fas fa-leaf</v-icon>
          {{ texts.analise_ambiental }}
        </v-btn>
      </v-col>
    </v-row>
    <hr class="my-3" />

    <v-row v-if="mapReference == idx">
      <v-col>
        <div :id="'map-' + idx" class="map-container"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['texts', 'trackOrigem', 'idx'],

  data() {
    return {
      geocoder: null,
      mapReference: null,
      nicePlanetURL: 'https://niceplanet.com.br'
    }
  },

  methods: {
    showMap(idx) {
      if (this.mapReference == null) {
        this.mapReference = null
        this.mapReference = idx

        this.geocoder = new google.maps.Geocoder()

        this.codeAddress(
          this.trackOrigem[idx].faz_nome +
            ', ' +
            this.trackOrigem[idx].faz_estado,
          resOrigem => {
            this.printMap(resOrigem, idx)
          }
        )
      } else {
        this.mapReference = null
      }
    },

    codeAddress(address, callback) {
      this.geocoder.geocode({ address: address }, function(results, status) {
        if (status == 'OK') {
          var loc = results[0].geometry.location
          callback({ lat: loc.lat(), lng: loc.lng() })
        }
      })
    },

    printMap(middlePoint, idx) {
      let map = new google.maps.Map(document.getElementById('map-' + idx), {
        zoom: 16,
        disableDefaultUI: true,
        center: middlePoint,
        mapTypeId: 'terrain',
        zoomControl: true
      })

      var pin = new google.maps.Marker({
        position: middlePoint,
        map: map,
        title: this.trackOrigem[idx].faz_name
      })

      pin.setMap(map)
    },

    showAmbiental() {
      this.$store.state.home = false
      this.$store.state.history = false
      this.$store.state.ambiental = true
      this.$store.state.blockchain = false
      this.$store.state.prevNavigation = 'history'
      this.$store.state.headerTitle = 'Análise Ambiental'
    }
  }
}
</script>

<style lang="scss" scoped>
.container-infos {
  .infos {
    font-size: 16px;
    width: 100%;

    p {
      color: $secondary-color;
      margin-bottom: 5px;
      font-weight: 500;
      text-transform: capitalize;
    }
    span {
      display: block;
      color: $quaternary-color;
      text-transform: capitalize;
    }
  }

  .btns-container {
    @media screen and (max-width: 400px) {
      flex-direction: column;
      .col {
        max-width: 100%;
        margin: 5px auto;

        button {
          width: 100%;
        }
      }
    }

    button {
      font-size: 9px;
    }
  }

  hr {
    border: 1px solid $tertiary-color;
  }

  .map-container {
    min-height: 200px;
    margin: 0 0 1rem 0;
  }
}

.container-infos:not(:first-of-type) {
  margin: 3rem 0 0 0;
}

.niceplanet-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.niceplanet-brand {
  height: auto;
  max-width: 80px;
  object-fit: center;
  object-position: center;
  width: auto;

  @media screen and (min-width: 400px) {
    max-width: 100px;
  }
}

.id-monitoramento {
  display: flex;
  margin: 1rem 0 0 0;

  .id {
    margin: 0 0 0 0.2rem;
    width: fit-content;
  }
}

.laudo {
  margin: 0.5rem 0 0 0;
  text-transform: none !important;
  width: 100%;

  .url-laudo {
    text-transform: none;
  }
}

.show-map {
  font-size: 9px;
  .v-btn__content {
    letter-spacing: normal;
  }
}
</style>
