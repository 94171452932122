<template>
  <div class="barcellos-retail">
    <v-btn id="btn_chatbot" class="go-chat-btn" @click="goChat()" depressed>
      <v-img
        src="@/assets/barcellos/assistente_virtual.png"
        max-width="60"
        max-height="60"
        class="mr-3"
      />
      <span class="label">{{ texts.botao_chatbot }}</span>
    </v-btn>
  </div>
</template>

<script>
import { goChat as $goChat } from '@/utils/chat'

export default {
  props: ['texts', 'track', 'trackOrigem'],

  methods: {
    goChat() {
      return $goChat()
    }
  }
}
</script>

<style lang="scss" scoped>
.barcellos-retail::v-deep {
  padding: 32px 0 0 0;

  .go-chat-btn {
    align-items: center;
    background-color: #f1bb00 !important;
    border-radius: 8px;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    height: auto;
    justify-content: center;
    padding: 1rem;
    width: 100%;

    .v-btn__content {
      width: 100%;
    }

    .label {
      color: white;
      display: block;
      font-family: 'Poppins', sans-serif !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 0 0 0 16px;
      text-align: left;
      text-transform: none;
      white-space: pre-wrap;
      width: 100%;
    }
  }
}
</style>
