import { render, staticRenderFns } from "./InternCard.vue?vue&type=template&id=58e89ab5&scoped=true&"
import script from "./InternCard.vue?vue&type=script&lang=js&"
export * from "./InternCard.vue?vue&type=script&lang=js&"
import style0 from "./InternCard.vue?vue&type=style&index=0&id=58e89ab5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58e89ab5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText})
