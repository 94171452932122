<template>
  <v-card id="AmbientalCard" class="Ecotrace-card">
    <v-card-text class="px-6 py-12">
      <v-row class="mb-4">
        <v-col>
          <div
            class="d-flex justify-center align-center shadow-container ambiental-title py-5 px-7"
          >
            <v-img
              src="@/assets/icons/selo_ambiental.png"
              max-width="58"
              max-height="80"
              class="mr-5"
            ></v-img>
            <h2 class="headline">{{ texts.propriedade_conforme }}</h2>
          </div>
        </v-col>
      </v-row>

      <v-row
        class="justify-space-between align-stretch"
        v-for="(item, idx) in dataAmbiental"
        :key="idx"
      >
        <v-col cols="8" class="d-flex align-center">
          <span class="subtitle-1 font-weight-medium">{{ item.name }}</span>
        </v-col>
        <v-col cols="4" class="d-flex align-center justify-center">
          <span class="subtitle-1 font-weight-medium primary--text">
            {{ texts.nada_consta }}
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12">
          <span class="subtitle-1 analysis-owner">
            Empresa responsável pelo monitoramento:
          </span>
          <img
            alt="NicePlanet Geotecnologia"
            class="niceplanet-brand"
            src="../../assets/niceplanet.png"
            title="NicePlanet Geotecnologia"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['texts', 'track', 'show'],

  data() {
    return {
      dataAmbiental: null
    }
  },

  created() {
    this.dataAmbiental = this.track.ambiental
    this.$store.state.origem = false
  },

  mounted() {
    window.scrollTo(0, 0)
  },

  methods: {
    iconFilter(value) {
      switch (value) {
        case '1':
          return 'desmatamento'
        case '2':
          return 'trabalho_escravo'
        case '3':
          return 'terras_indigenas'
        case '4':
          return 'embargo'
        case '5':
          return 'unid_conservacao'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#AmbientalCard::v-deep {
  .shadow-container.ambiental-title {
    h2 {
      display: inline-block;
    }
  }

  .shadow-container {
    box-shadow: 0px 3px 22px #00000017;
    border-radius: 11px;
  }

  .shadow-container.list-item {
    width: 70px;
    height: 70px;
    display: inline-block;
    padding: 19px 7px;
    text-align: center;
    margin-right: 18px;
  }
}

.analysis-owner {
  color: $quaternary-color;
}

.niceplanet-brand {
  height: auto;
  margin: 0.5rem 0 0 0;
  max-width: 160px;
  object-fit: center;
  object-position: center;
  width: auto;
}
</style>
