<template>
  <div class="container-infos">
    <v-row>
      <v-col class="d-flex justify-space-between align-center">
        <div class="infos">
          <p>{{ origin.faz_nome.toLowerCase() }}</p>
          <span
            >{{ origin.faz_cidade.toLowerCase() }} -
            {{ origin.faz_estado }}</span
          >
          <!-- <span>Bioma: {{ origin.bioma.toLowerCase() }}</span> -->
          <div class="id-monitoramento" v-if="origin.cod_empresa_ambiental">
            {{ texts.id_monitoramento }}:
            <span class="id" ref="noreferrer; noopener" target="_blank">
              {{ origin.cod_empresa_ambiental }}
            </span>
          </div>
          <span class="laudo" v-if="origin.cod_empresa_ambiental">
            {{ texts.empresa_responsavel_monitoramento }}:
            <a
              class="url-laudo"
              :href="nicePlanetURL"
              ref="noreferrer; noopener"
              target="_blank"
            >
              {{ nicePlanetURL }}
            </a>
          </span>
        </div>
        <v-img
          v-if="track.industry.show_analysis && origin.data_monitoramento"
          src="@/assets/icons/selo_ambiental.png"
          max-width="34"
          max-height="47"
        ></v-img>
      </v-col>
    </v-row>
    <v-row v-if="origin.faz_car && origin.faz_car.trim().length > 0">
      <v-col>
        CAR:
        <span
          class="d-block primary--text font-weight-bold"
          style="font-size: 11px"
          >{{ origin.faz_car }}</span
        >
      </v-col>
    </v-row>

    <v-row no-gutters class="btns-container my-5">
      <v-col cols="6">
        <v-btn
          :disabled="!hasPolygon"
          :id="`btn_ver_mapa_${idx}`"
          class="show-map"
          color="primary"
          rounded
          outlined
          @click="showMap(idx)"
        >
          <v-icon left color="primary" size="13">fas fa-map-marker-alt</v-icon>
          <span class="quaternary--text">{{ texts.ver_mapa }}</span>
        </v-btn>
      </v-col>

      <v-col v-if="origin.analysis" cols="6" class="text-center">
        <v-btn
          :id="`btn_analise_ambiental_${idx}`"
          rounded
          depressed
          dark
          color="success"
          @click="showAmbiental(origin)"
        >
          <v-icon left size="13">fas fa-leaf</v-icon>
          {{ texts.analise_ambiental }}
        </v-btn>
      </v-col>
      <v-col v-else cols="6" class="text-center niceplanet-wrapper">
        <img
          alt="NicePlanet Geotecnologia"
          class="niceplanet-brand"
          src="../../assets/niceplanet.png"
          title="NicePlanet Geotecnologia"
        />
      </v-col>
    </v-row>

    <v-row v-if="mapReference == idx">
      <v-col>
        <div :id="'map-' + idx" class="map-container"></div>
      </v-col>
    </v-row>

    <hr class="my-3" />
  </div>
</template>

<script>
export default {
  props: ['texts', 'idx', 'track', 'origin'],
  data() {
    return {
      geocoder: null,
      mapReference: null
    }
  },

  watch: {
    origin: {
      handler() {
        this.mapReference = null
        // do stuff
      },
      deep: true
    }
  },

  computed: {
    genAnalysisURL() {
      const __BASE_URL = 'https://public.smgeo.com.br/propriedade'
      return (codigoAnalise) => `${__BASE_URL}/${codigoAnalise}`
    },
    nicePlanetURL() {
      return 'https://niceplanet.com.br'
    },
    hasPolygon() {
      return (
        this.origin.analysis &&
        this.origin.analysis?.polygon?.coordinates &&
        this.origin.analysis.polygon.coordinates.length > 0
      )
    }
  },

  methods: {
    showMap(idx) {
      if (this.mapReference == null) {
        this.mapReference = null
        this.mapReference = idx

        this.geocoder = new google.maps.Geocoder()

        const target = this.origin
        const { analysis: geoJSON = {} } = target

        if (Object.keys(geoJSON).length) {
          setTimeout(() => this.printMapPolygon(geoJSON.polygon, idx), 500)
        }
      } else {
        this.mapReference = null
      }
    },

    printMapPolygon(coordinates, idx) {
      function processPoints(geometry, callback, thisArg) {
        if (geometry instanceof google.maps.LatLng) {
          callback.call(thisArg, geometry)
        } else if (geometry instanceof google.maps.Data.Point) {
          callback.call(thisArg, geometry.get())
        } else {
          geometry.getArray().forEach((g) => {
            processPoints(g, callback, thisArg)
          })
        }
      }

      const map = new google.maps.Map(document.getElementById('map-' + idx), {
        zoom: 16,
        disableDefaultUI: true,
        mapTypeId: 'satellite',
        zoomControl: true
      })

      map.data.addGeoJson({
        type: 'Feature',
        property: 'polygon',
        geometry: coordinates
      })

      map.data.setStyle(() => ({
        strokeColor: '#3fab36',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: '#3fab36',
        fillOpacity: 0.32
      }))

      const bounds = new google.maps.LatLngBounds()
      map.data.forEach((feature) => {
        processPoints(feature.getGeometry(), bounds.extend, bounds)
      })
      map.fitBounds(bounds)
    },

    showAmbiental(data) {
      this.$store.state.home = false
      this.$store.state.history = false
      this.$store.state.ambiental = true
      this.$store.state.blockchain = false
      this.$store.state.prevNavigation = 'history'
      this.$store.state.headerTitle = this.texts.analise_ambiental_title
      this.$store.state.producer_data = data
    }
  }
}
</script>

<style lang="scss" scoped>
.container-infos {
  .infos {
    font-size: 16px;
    width: 100%;

    p {
      color: $secondary-color;
      margin-bottom: 5px;
      font-weight: 500;
      text-transform: capitalize;
    }
    span {
      display: block;
      color: $quaternary-color;
      text-transform: capitalize;
    }
  }

  .btns-container {
    @media screen and (max-width: 400px) {
      flex-direction: column;
      .col {
        max-width: 100%;
        margin: 5px auto;

        button {
          width: 100%;
        }
      }
    }

    button {
      font-size: 9px;
    }
  }

  hr {
    border: 1px solid $tertiary-color;
  }

  .map-container {
    min-height: 200px;
    margin: 0 0 1rem 0;
  }
}

.container-infos:not(:first-of-type) {
  margin: 1rem 0 0 0;
}

.niceplanet-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.niceplanet-brand {
  height: auto;
  max-width: 80px;
  object-fit: center;
  object-position: center;
  width: auto;

  @media screen and (min-width: 400px) {
    max-width: 100px;
  }
}

.id-monitoramento {
  display: flex;
  margin: 1rem 0 0 0;

  .id {
    margin: 0 0 0 0.2rem;
    width: fit-content;
  }
}

.laudo {
  margin: 0.5rem 0 0 0;
  text-transform: none !important;
  width: 100%;

  .url-laudo {
    text-transform: none;
  }
}

.show-map {
  font-size: 9px;
  .v-btn__content {
    letter-spacing: normal;
  }
}
</style>
