<template>
  <v-timeline id="Ecotrace-timeline" align-top dense>
    <v-timeline-item id="info-analise" v-if="false" fill-dot color="white">
      <template v-slot:icon>
        <i class="fas fa-leaf" style="color: #4eab37;"></i>
      </template>
      <h2 class="title text-uppercase primary--text mt-1">
        {{ texts.analise_ambiental }}
      </h2>
      <AnaliseAmbiental :texts="texts" :show="true" :track="track" />
    </v-timeline-item>
    <v-timeline-item id="info-origem" fill-dot color="white">
      <template v-slot:icon>
        <v-img
          contain
          max-width="20"
          max-height="20"
          src="@/assets/icons/origem.png"
        ></v-img>
      </template>
      <h2 class="title text-uppercase primary--text mt-1">
        {{ texts.info_origem }}
      </h2>
      <template v-for="(origem, idx) in trackOrigem.data">
        <Origins
          :texts="texts"
          :trackOrigem="trackOrigem.data"
          :idx="idx"
          :key="'key-' + idx"
        />
      </template>
    </v-timeline-item>
    <v-timeline-item id="info-industria" fill-dot color="white" right>
      <template v-slot:icon>
        <v-img
          contain
          max-width="18"
          max-height="18"
          src="@/assets/icons/industria.png"
        ></v-img>
      </template>
      <h2 class="title text-uppercase primary--text mt-1">
        {{ texts.info_industria }}
      </h2>
      <InfoIndustria
        :texts="texts"
        :track="track"
        :trackDestino="trackDestino"
        :trackOrigem="trackOrigem"
      />
    </v-timeline-item>
    <v-timeline-item id="info-varejo" fill-dot color="white">
      <template v-slot:icon>
        <v-icon size="16" color="secondary">far fa-comments</v-icon>
      </template>
      <h2 class="title text-uppercase primary--text mt-1">
        {{ texts.info_varejo }}
      </h2>
      <InfoVarejo :texts="texts" :track="track" :trackOrigem="trackOrigem" />
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import Origins from '@/components/friboi-cards/ProductorOrigin'
import AnaliseAmbiental from '@/components/friboi-cards/AnaliseAmbiental'
import InfoIndustria from '@/components/friboi-cards/InfoIndustria'
import InfoVarejo from '@/components/blocks/InfoVarejo'
export default {
  props: ['texts', 'track', 'trackOrigem', 'trackDestino'],
  components: {
    Origins,
    InfoIndustria,
    InfoVarejo,
    AnaliseAmbiental
  },

  created() {
    if (this.$route.query.origem) {
      this.$store.state.home = false
      this.$store.state.history = true
      this.$store.state.origem = true
      //this.$store.state.prevNavigation = "home";
      this.$store.state.headerTitle = this.texts.info_origem
      return
    }
  }
}
</script>

<style lang="scss" scoped>
#Ecotrace-timeline::v-deep {
  padding: 0;
  margin-top: 24px;
  margin-left: -15px;

  &:before {
    width: 3px;
    left: calc(48px - 9.5px);
    background: rgb(63, 171, 54);
    background: linear-gradient(
      180deg,
      rgba(63, 171, 54, 1) 0%,
      rgba(224, 226, 232, 1) 98%
    );
    max-height: 97%;
  }

  .v-timeline-item {
    justify-content: center;

    .v-timeline-item__divider {
      min-width: 46px;

      .v-timeline-item__dot {
        box-shadow: none;

        .v-timeline-item__inner-dot {
          border: 1px solid $primary-color;
          border-color: $primary-color !important;
        }
      }
    }

    .v-timeline-item__body {
      margin-left: 17px;
    }
  }
}
</style>
