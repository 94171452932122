export default {
  inicio: 'Inicio',
  origem: 'Origen',
  industria: 'Industria',
  dt_producao: 'Fecha de producción',
  dt_validade: 'Fecha de caducidad',
  dt_recebimento: 'Fecha de recepción',
  codigo_rastreio: 'Código de rastreo',
  tipo_corte: 'Tipo de corte',
  lote_producao: 'Lote de producción',
  nome: 'Nombre',
  cnpj: 'CNPJ',
  email: 'Correo electrónico',
  telefone: 'Teléfono',
  fazenda: 'Finca',
  cidade: 'Ciudad',
  historico_produto: 'Historial del producto',
  dados_produto: 'Datos del producto',
  info_origem: 'Información de origen - Biomas',
  ver_mapa: 'Ver en el mapa',
  analise_ambiental: 'Análisis ambiental',
  certificados: 'Certificados',
  blockchain: 'Blockchain',
  contato: 'Contacto',
  selo_ecotrace: 'Sello Ecotrace',
  info_industria: 'Información de la industria',
  dados_unidade_produtora: 'Datos de la unidad productora',
  localizacao_unidade_produtora: 'Ubicación de la unidad productora',
  certificados_unidade_produtora: 'Certificados de la unidad productora',
  planta_habilitada_exportacao: 'Planta habilitada para exportación',
  ver_lista_completa: 'Ver lista completa',
  ver_lista_reduzida: 'Ver lista reducida',
  info_varejo: 'Asistente Virtual',
  botao_chatbot: '¿Le gustaría hablar con nuestro asistente virtual?',
  localizacao_varejo: 'Ubicación de la tienda',
  ajuda_sobre_produto: '¿Necesita ayuda sobre este producto?',
  fale_com_barcellos: 'Hable con Barcellos',
  fazer_pergunta: 'Hacer una pregunta',
  propriedade_conforme: '¡Propiedad 100% APTA!',
  nada_consta: 'Nada consta',
  certificados_title: 'SIF - REGISTRO DE ESTABLECIMIENTOS',
  certificados_text:
    'El registro de establecimientos ante el Servicio de Inspección Federal - SIF es obligatorio para todas las industrias de productos de origen animal: carne y derivados, leche y derivados, pescados, miel y huevos; que pretenden comercializar sus productos fuera de las fronteras estatales o federales.',
  blockchain_title: 'Datos protegidos por blockchain',
  blockchain_text:
    'La blockchain es una tecnología de registro distribuido que busca la descentralización como medida de seguridad. Son bases de registros y datos distribuidos y compartidos que tienen la función de crear un índice global para todas las transacciones que ocurren en un mercado determinado.',
  seloecotrace_title: 'Datos protegidos por blockchain',
  seloecotrace_text:
    'Blockchain es una tecnología de registro distribuido que busca la descentralización como medida de seguridad. Son bases de registros y datos distribuidos y compartidos que tienen la función de crear un índice global para todas las transacciones que ocurren en un mercado determinado.',
  deixe_mensagem: 'Deje su mensaje',
  mensagem_header_text:
    'Si encuentra algún problema con este producto o tiene alguna pregunta, por favor háganoslo saber. Responderemos lo más pronto posible.',
  enviar_minha_duvida: 'Enviar mi duda',
  produto_rastreabilidade: 'Producto con trazabilidad de producción',
  consulta_dados_historico_ecotrace:
    'Ahora puede consultar todos los datos del historial de producción de este producto con tecnología',
  dados_disponiveis_breve: 'Los datos estarán disponibles en breve',
  rastreabilidade_da_producao: 'Trazabilidad de producción',
  dados_disponiveis_em:
    'Los datos de este producto están en proceso y estarán disponibles en',
  produto_frigol: 'Producto Frigol',

  Desmatamento: 'Deforestación',
  'Trabalho escravo': 'Trabajo esclavo',
  'Invasão de Terras Indígenas': 'Invasión de Tierras Indígenas',
  'Embargo IBAMA': 'Embargo de IBAMA',
  'Invasão de Unidade de Conservação Ambiental':
    'Invasión de Unidad de Conservación Ambiental',

  propriedade_nao_conforme: '¡Propiedad no apta!',
  analise_status_conforme: 'Conforme',
  analise_status_nao_conforme: 'No Conforme',
  propriedade_com_inconsistencia: '¡Propiedad con inconsistencia!'
}
