<template>
  <div id="Footer" class="px-4 pl-12 py-8">
    <v-row class="contact">
      <div class="brand-wrapper">
        <picture class="brand">
          <source
            srcset="../../assets/barcellos/bbq-secrets-brand-vertical.jpg"
            media="(min-width: 375px)"
          />
          <img
            src="../../assets/barcellos/bbq-secrets-brand-horizontal.jpg"
            alt="BBQ Secrets"
          />
        </picture>
      </div>
      <div class="cta-wrapper">
        <h3 class="title">{{ texts.ajuda_sobre_produto }}</h3>
        <parallelogram-button
          :label="texts.fale_com_barcellos"
          size="lg"
          :onClick="goChat"
        >
          <v-img
            contain
            src="@/assets/icons/icone-pergunta.png"
            max-width="24"
            max-height="24"
            slot="icon"
          />
        </parallelogram-button>
      </div>
    </v-row>
  </div>
</template>

<script>
import ParallelogramButton from './ParallelogramButton.vue'

import { goChat as $goChat } from '@/utils/chat'

export default {
  components: { ParallelogramButton },
  methods: {
    goChat() {
      return $goChat()
    }
  },
  props: ['texts']
}
</script>

<style lang="scss" scoped>
#Footer::v-deep {
  background-color: #eaeaea;
  box-sizing: border-box;
  padding: 0 16px 72px !important;

  .contact {
    align-items: center;
    background-color: #eaeaea;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 16px 16px 24px;

    > .brand-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 0 8px 0;
      width: 100%;

      > .brand {
        height: 100%;
        object-fit: contain;
        object-position: center;
        width: auto;
      }
    }

    > .cta-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 32px);

      > .title {
        color: #2b2b2b;
        font-family: 'Poppins', sans-serif !important;
        font-size: 14px !important;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin: 0 0 8px 0;
      }
    }

    .parallelogram-button {
      width: 100%;
    }

    @media screen and (min-width: 375px) {
      padding: 16px;
      flex-direction: row;

      > .cta-wrapper {
        width: 64%;
      }

      > .brand-wrapper {
        margin: 0;
        width: 32%;
      }

      .parallelogram-button {
        width: fit-content;
      }
    }
  }
}
</style>
