export default {
  inicio: 'Home',
  origem: 'Origin',
  industria: 'Industry',
  dt_producao: 'Production date',
  dt_validade: 'Expiration date',
  dt_recebimento: 'Reception date',
  codigo_rastreio: 'Tracking code',
  tipo_corte: 'Cut type',
  lote_producao: 'Production batch',
  nome: 'Name',
  cnpj: 'CNPJ',
  email: 'E-mail',
  telefone: 'Phone',
  fazenda: 'Farm',
  cidade: 'City',
  historico_produto: 'Product history',
  dados_produto: 'Product data',
  info_origem: 'Origin information - Biomes',
  ver_mapa: 'View on map',
  analise_ambiental: 'Environmental analysis',
  certificados: 'Certificates',
  blockchain: 'Blockchain',
  contato: 'Contact',
  selo_ecotrace: 'Ecotrace seal',
  info_industria: 'Industry information',
  dados_unidade_produtora: 'Production unit data',
  localizacao_unidade_produtora: 'Production unit location',
  certificados_unidade_produtora: 'Production unit certificates',
  planta_habilitada_exportacao: 'Export enabled plant',
  ver_lista_completa: 'View full list',
  ver_lista_reduzida: 'View short list',
  info_varejo: 'Virtual assistant',
  botao_chatbot: 'Would you like to talk to our virtual assistant?',
  localizacao_varejo: 'Retail location',
  ajuda_sobre_produto: 'Need help with this product?',
  fale_com_barcellos: 'Contact Barcellos',
  fazer_pergunta: 'Ask a question',
  propriedade_conforme: 'Property 100% APTA!',
  nada_consta: 'No Records Found',
  certificados_title: 'SIF - ESTABLISHMENT REGISTRATION',
  certificados_text:
    'Establishment registration with the Federal Inspection Service - SIF is mandatory for all industries producing animal products: meat and derivatives, milk and derivatives, fish, honey, and eggs; that intend to sell their products outside state or federal borders.',
  blockchain_title: 'Data protected by blockchain',
  blockchain_text:
    'Blockchain is a distributed ledger technology that aims for decentralization as a security measure. It is a distributed and shared record and data base that functions to create a global index for all transactions that occur in a given market.',
  seloecotrace_title: 'Data protected by blockchain',
  seloecotrace_text:
    'Blockchain is a distributed ledger technology that aims for decentralization as a security measure. It is a distributed and shared record and data base that functions to create a global index for all transactions that occur in a given market.',
  deixe_mensagem: 'Leave a message',
  mensagem_header_text:
    'If you identify any problem with this product or have any questions, please let us know. We will respond as soon as possible.',
  enviar_minha_duvida: 'Send my question',
  produto_rastreabilidade: 'Product with production traceability',
  consulta_dados_historico_ecotrace:
    'Now you can consult all production history data for this product with technology',
  dados_disponiveis_breve: 'The data will be available soon',
  rastreabilidade_da_producao: 'Production traceability',
  dados_disponiveis_em:
    'The data for this product is currently being processed and will be available in',
  produto_frigol: 'Frigol product',
  propriedade_nao_conforme: 'Property not suitable!',
  analise_status_conforme: 'Compliant',
  analise_status_nao_conforme: 'Non-compliant',
  propriedade_com_inconsistencia: 'Property with inconsistency!',

  Desmatamento: 'Deforestation',
  'Trabalho escravo': 'Slave labor',
  'Invasão de Terras Indígenas': 'Invasion of Indigenous Lands',
  'Embargo IBAMA': 'IBAMA status',
  'Invasão de Unidade de Conservação Ambiental':
    'Invasion of Environmental Conservation Unit'
}
