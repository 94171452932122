<template>
  <v-card id="InternCard" class="barcellos-card">
    <v-card-text class="px-4">
      <Timeline
        :texts="texts"
        :track="track"
        :trackDestino="trackDestino"
        :trackOrigem="trackOrigem"
      />
    </v-card-text>
    <Footer :texts="texts" />
    <BottomNavigation :texts="texts" />
  </v-card>
</template>

<script>
import Timeline from '@/components/Barcellos/Timeline'
import Footer from '@/components/Barcellos/Footer'
import BottomNavigation from '@/components/Barcellos/BottomNavigation'

export default {
  props: ['texts', 'track', 'trackDestino', 'trackOrigem'],
  components: { BottomNavigation, Footer, Timeline }
}
</script>

<style lang="scss" scoped>
.barcellos-card {
  background-color: #f9f9f7;
  border-radius: 0;
  box-shadow: none !important;
}
</style>
