<template>
  <div class="wrap">
    <v-pagination
      :color="color"
      :length="lastPage"
      :value="currentPage"
      v-on:input="setPage"
    />
  </div>
</template>

<script>
export default {
  props: ['currentPage', 'lastPage', 'barcellos'],

  computed: {
    color() {
      console.log('pagination ->', this.barcellos)
      if (this.barcellos) return '#f1bb00'
      return ''
    }
  },

  methods: {
    setPage(page) {
      window.scrollTo(0, 0)
      this.$emit('next-page', page - this.currentPage)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  justify-content: space-around;
}
</style>
