<template>
  <v-card id="BlockChainCard" class="Ecotrace-card">
    <v-btn
      absolute
      right
      fab
      small
      depressed
      color="white"
      class="close-btn secondary--text"
      @click="close"
    >
      <v-icon>fas fa-times</v-icon>
    </v-btn>
    <v-card-text class="px-8 pt-12">
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <v-img
            src="@/assets/icons/icone-blockchain-verde.png"
            max-width="177"
            max-height="177"
            contain
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <p
            class="secondary--text text-uppercase font-weight-medium subtitle-1 mb-0"
          >
            {{ texts.blockchain_title }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <p class="px-3">
            {{ texts.blockchain_text }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["texts"],
  methods: {
    close() {
      this.$store.state.blockchain = false;
      this.$store.state.home = true;
      this.$store.state.headerTitle = this.texts.dados_produto;
    }
  }
};
</script>

<style lang="scss" scoped>
#BlockChainCard {
  .close-btn {
    top: 22px;
    right: 22px;
  }
}
</style>
