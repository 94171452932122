<template>
    <v-card class="card-container">
        <v-app-bar 
            height="auto"
            class="card-header"
        >
            <v-col class="logo-container" md="10" cols="8">
                <v-img 
                contain
                max-height="30"
                position="left center"
                src="../../assets/logo_seara.png">
                </v-img>
            </v-col>
        </v-app-bar>
        <v-card-text>
            <v-row class="container-infos">
                <v-col class="track-infos" md="6" cols="12">
                <v-row class="destino-line title-line">
                    <div class="baloon-text baloon-destino">
                        <i class="track-timeline-icon cow-icon">
                            <SteakSVG />
                        </i>
                        <strong style="text-transform: capitalize;">{{trackDestino.name}} {{sif}}</strong>
                    </div>
                </v-row>

                <v-row class="destino-line">
                    <v-col>
                    <strong class="destino-text">{{texts.dt_producao}}</strong>
                    <div>{{track.production_date | formatDate}}</div>
                    </v-col>
                    <v-col>
                    <strong class="destino-text">{{texts.cnpj}}</strong>
                    <div>{{trackDestino.document_number | formatCpfCnpj}}</div>
                    </v-col>
                </v-row>

                <v-row class="destino-line">
                    <v-col>
                    <strong class="destino-text">{{texts.telefone}}</strong>
                    <div>{{trackDestino.phone}}</div>
                    </v-col>
                    <v-col>
                    <strong class="destino-text">{{texts.email}}</strong>
                    <div>{{trackDestino.email}}</div>
                    </v-col>
                </v-row>
                <br>
                <v-row class="origem-line title-line" v-if="trackOrigem.length > 0">
                    <div class="baloon-text baloon-origem">
                        <i class="track-timeline-icon cow-icon">
                            <FarmerSVG /></i>
                        <strong>{{texts.origem}}</strong>
                    </div>
                </v-row>
            
                <v-row class="origem-line" v-if="trackOrigem.length > 0">
                    <v-col>
                    <strong class="origem-text">{{texts.fazenda}}</strong>
                    </v-col>
                    <v-col>
                    <strong class="origem-text">{{texts.cidade}}</strong>
                    </v-col>
                </v-row>
                
                <v-row class="origem-line" v-for="(item,i) in trackOrigem" :key="i">
                    <v-col>
                    <div class="origem-data">{{item.faz_nome}}</div>
                    </v-col>
                    <v-col>
                    <div class="origem-data">{{item.faz_cidade}} / {{item.faz_estado}}</div>
                    </v-col>
                </v-row>

                </v-col>
                <v-col md="6" cols="12">
                <div id="map"></div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import SteakSVG from '../../components/icons/SteakSVG'
import FarmerSVG from '../../components/icons/FarmerSVG'
import mockDataSeara from '../../assets/dataMockSeara'
import searaLogo from '../../assets/logo_seara.png'

// Languages //
import langPTBR from './pt-br.ts'
import langCN from './cn.ts'

export default {
    components: {
        SteakSVG,
        FarmerSVG
    },
    data: function() {
        return {
            track : {},
            geocoder : null,
            frigorifico : '',
            sif: '',
            data: {},
            texts: {},
            trackDestino: '',
            trackOrigem: []
        }
    },

    computed: {
        changeDataValue() {
            return this.data
        }
    },

    created() {
        this.track = mockDataSeara
        this.trackDestino = mockDataSeara.destiny.find(x => x.sif === this.$route.query.sif);
        this.sif = this.trackDestino.sif

        axios.get(`https://ecotrace-api-dev.ecotrace.solutions/api/v1/production-order/bovine?dt_desossa=${this.$route.query.dp}&nr_lote_abate=${this.$route.query.lp}&ind_numero=${this.$route.query.sif}`)
            .then( response => {
                response.data.data.forEach(element => {
                    this.trackOrigem.push({
                        faz_nome : element.faz_nome.toLowerCase(),
                        faz_cidade : element.faz_cidade.toLowerCase(),
                        faz_estado : element.faz_estado
                    })
                })
            })

        if(this.track && this.trackDestino) {
            if(this.$route.query.lg) {
                switch (this.$route.query.lg) {
                    case 'pt-br':
                        this.texts = langPTBR
                        break;
                    case 'pt':
                        this.texts = langPTBR
                        break;
                    case 'cn':
                        this.texts = langCN
                        break;
                    case 'zh-cn':
                        this.texts = langCN
                        break;
                    case 'zhHans':
                        this.texts = langCN
                        break;
                    default:
                        this.texts = langPTBR
                        break
                }
            } else {
                this.texts = langPTBR
            }
        }
    },

    mounted: function() {
        this.geocoder = new google.maps.Geocoder();
        this.codeAddress(this.trackDestino.city+", "+this.trackDestino.state, (resDestiny) => {
            this.printMap(this.trackDestino, resDestiny, this.track.origin)
        })
    },

    methods: {
        codeAddress(address, callback) {
            this.geocoder.geocode( { 'address': address}, function(results, status) {
                if (status == 'OK') {
                var loc = results[0].geometry.location;
                callback({lat: loc.lat(), lng: loc.lng()});
                }
            });
        },

        printMap(r, resIndustria,  orign) {
            let map = new google.maps.Map(document.getElementById('map'), {
                zoom: 8,
                disableDefaultUI: true,
                center: resIndustria,
                mapTypeId: 'terrain',
                zoomControl: true
            });

        var logoMarca = ''
        this.logoMarca = searaLogo
        
        // console.log(r)
        var destinyContent = "<div style='color: #272b62; font-weight: bold;'> "+( r.sif ? 'SIF ' + r.sif : r.name )+"</div>" + "<br> <img src=" + this.logoMarca + " height='30' width='auto' style='display: block; margin: 0 auto; text-align: center;'></img>"
        
        var mi = new google.maps.Marker({
            position: resIndustria,
            map: map,
            title: r.name
        });

        var infoI = new google.maps.InfoWindow({
            content: destinyContent
        });
        infoI.open(map, mi)

        orign.forEach(e => {
            this.codeAddress(e.city+", "+e.state, (resOrigin) => {
            var originContent = "<div style='color: #5d812e; font-weight: bold;'>" + e.name + "</div>" + "<br>" + "<svg id='SVG_MAP_cow' fill='#5d812e' enable-background='new 0 0 30 30' height='30' viewBox='0 0 512 512' width='30' style='display: block; margin: 0 auto; text-align: center;' xmlns='http://www.w3.org/2000/svg'><path d='m304.703125 400.601562h-160.667969c-17.71875 0-32.132812 14.414063-32.132812 32.132813v79.265625h224.933594v-79.265625c0-17.71875-14.414063-32.132813-32.132813-32.132813zm0 0'/><path d='m170.710938 309.660156c-2.5625-2.558594-5.949219-4.089844-9.539063-4.351562v65.292968h126.398437v-65.292968c-3.589843.261718-6.980468 1.792968-9.550781 4.363281-14.328125 14.328125-33.378906 22.21875-53.648437 22.21875-20.269532 0-39.320313-7.890625-53.660156-22.230469zm0 0'/><path d='m317.570312 308.199219v63.75c28.105469 5.941406 49.265626 30.933593 49.265626 60.785156v79.265625h50.335937c8.277344 0 15-6.71875 15-15v-48.199219c0-69.230469-49.28125-127.179687-114.601563-140.601562zm0 0'/><path d='m131.171875 308.199219c-65.320313 13.421875-114.601563 71.371093-114.601563 140.601562v48.199219c0 8.28125 6.71875 15 15 15h50.332032v-79.265625c0-29.851563 21.160156-54.84375 49.269531-60.785156zm0 0'/><path d='m433.261719 77.136719h-81.480469c-1.132812-42.726563-36.21875-77.136719-79.210938-77.136719h-96.398437c-42.996094 0-78.078125 34.410156-79.214844 77.136719h-81.457031c-8.28125 0-15 6.730469-15 15.011719 0 27.199218 22.050781 49.25 49.25 49.25h349.261719c27.199219 0 49.25-22.050782 49.25-49.25 0-8.28125-6.722657-15.011719-15-15.011719zm0 0'/><path d='m335.769531 175.667969v-4.269531h-222.800781v4.269531c0 61.621093 49.878906 111.398437 111.402344 111.398437 61.621094 0 111.398437-49.875 111.398437-111.398437zm0 0'/></svg>"
            var mf = new google.maps.Marker({
                position: resOrigin,
                map: map,
                icon : 'farmer.png',
                title: e.name
            });

            var citiesCoordinates = [
                resOrigin,
                resIndustria
            ];
            var rastreioPath = new google.maps.Polyline({
                path: citiesCoordinates,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2
            });
            rastreioPath.setMap(map);
            });
        })

        },
        middlePoint(lat1, lng1, lat2, lng2) {
        if (typeof(Number.prototype.toRad) === "undefined") {
            Number.prototype.toRad = function() {
            return this * Math.PI / 180;
            }
        }

        if (typeof(Number.prototype.toDeg) === "undefined") {
            Number.prototype.toDeg = function() {
            return this * 180 / Math.PI
            }
        }
        //-- Longitude difference
        var dLng = (lng2 - lng1).toRad();

        //-- Convert to radians
        lat1 = lat1.toRad();
        lat2 = lat2.toRad();
        lng1 = lng1.toRad();

        var bX = Math.cos(lat2) * Math.cos(dLng);
        var bY = Math.cos(lat2) * Math.sin(dLng);
        var lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY));
        var lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);

        //-- Return result
        return {lat: lat3.toDeg(), lng: lng3.toDeg()}
        }

    },
}

</script>

<style lang="scss" scoped>
    .card-container {
        border-radius: 12px;

        .card-header {
            background: linear-gradient(to bottom, #a7d071, #5c812d);
            box-shadow: none;
        }

        .container-infos {
            padding-left: 5px;

            @media screen and (max-width: 960px) {
                padding: 0;
            }

        .baloon-text {
            display: flex;
            padding: 5px 12px;
            padding-top: 7px;
            padding-right: 20px;
            align-items: center;
            justify-content: center;
            border-radius: 0 12px 12px 0;
          
            i {
                height: auto;
                margin-right: 12px;
            }
          
            strong {
                font-size: 16px;
                color: #ffffff;
                text-transform: uppercase;
                margin-right: 12px;
            }
        }

        .baloon-origem {
            background-color: #5d812e;
        }

        .baloon-destino {
            background-color: #272b62;
        }
          
        .track-infos, .id-header-text {
            position: relative;
            font-family: 'Roboto', Arial, Helvetica, sans-serif;
        }

        .origem-line {
            border-left: 3px solid #5d812e;

            &:nth-child(3) {
                padding-bottom: 30px;
            }

            strong.origem-text{
                color: #5d812e;
            }

            .origem-data {
                text-transform: capitalize;
            }

        }

        .destino-line {
            border-left: 3px solid #272b62;

            &:last-child {
                padding-bottom: 30px;
            }

            strong.destino-text{
                color: #272b62;
            }
        }

        #map {
            width: 100%;
            height: 100% !important;
            max-height: 400px;
            font-family: 'Roboto', Arial, Helvetica, sans-serif;
            font-weight: bold;

            @media screen and (max-width: 960px) {
                height: 300px !important;
            }
        }
      }
    }
</style>