export default {
  industria: '产业领域',
  dt_producao: '生产日期',
  dt_validade: '到期日',
  codigo_rastreio: '追踪码',
  tipo_corte: '切割方式',
  lote_producao: '生产批次',
  nome: '名称',
  cnpj: '公司税号',
  email: '电邮',
  telefone: '电话',
  fazenda: '农场',
  cidade: '城市',
  origem: '原产地',
  blockchain_title: '區塊鏈保護的數據',
  blockchain_text:
    '區塊鍊是一種分佈式註冊技術，旨在將權力下放作為一種安全措施。 它們是分佈和共享的記錄和數據的基礎，具有為給定市場中發生的所有交易創建全局索引的功能。',
  inicio: '首页',
  dt_recebimento: '收货日期',
  historico_produto: '产品历史',
  dados_produto: '产品数据',
  info_origem: '原产地信息 - 生态区',
  ver_mapa: '查看地图',
  analise_ambiental: '环境分析',
  certificados: '证书',
  blockchain: '区块链',
  contato: '联系',
  selo_ecotrace: 'Ecotrace标志',
  info_industria: '工业信息',
  dados_unidade_produtora: '生产单位数据',
  localizacao_unidade_produtora: '生产单位位置',
  certificados_unidade_produtora: '生产单位证书',
  planta_habilitada_exportacao: '出口合格工厂',
  ver_lista_completa: '查看完整列表',
  ver_lista_reduzida: '查看简化列表',
  info_varejo: '虚拟助手',
  botao_chatbot: '想要与我们的虚拟助手交谈吗？',
  localizacao_varejo: '零售店位置',
  ajuda_sobre_produto: '需要帮助吗？',
  fale_com_barcellos: '与Barcellos联系',
  fazer_pergunta: '提问',
  propriedade_conforme: '100%合格的财产！',
  nada_consta: '无记录',
  certificados_title: 'SIF - 工厂注册',
  certificados_text:
    '对于所有动物产品工业：肉类和副产品，奶制品和副产品，鱼类，蜂蜜和鸡蛋，如果想要在州际或联邦边界之外销售产品，则必须在联邦检验服务（SIF）注册。',
  seloecotrace_title: '区块链保护的数据',
  seloecotrace_text:
    '区块链是一种分布式注册技术，旨在将权力下放作为一种安全措施。 它们是分布和共享的记录和数据的基础，具有为给定市场中发生的所有交易创建全局索引的功能。',
  mensagem_header_text:
    '如果您对此产品有任何问题或疑问，请告诉我们。 我们将尽快回复。',
  enviar_minha_duvida: '发送我的问题',
  produto_rastreabilidade: '具有生产追溯的产品',
  consulta_dados_historico_ecotrace:
    '现在您可以使用技术查询此产品的所有生产历史数据',
  dados_disponiveis_breve: '数据将很快可用',
  rastreabilidade_da_producao: '生产追溯',
  dados_disponiveis_em: '此产品的数据正在处理中，将在',
  produto_frigol: 'Frigol产品',
  propriedade_nao_conforme: '不合格的财产',
  analise_status_conforme: '合格',
  analise_status_nao_conforme: '不合格',
  propriedade_com_inconsistencia: '不一致的财产'
}
