<template>
  <v-bottom-navigation
    id="Bottom-navigation"
    :value="optionActive"
    grow
    color="secondary"
    fixed
  >
    <v-btn @click="goBack">
      <span class="mt-1">{{ texts.inicio }}</span>
      <v-img
        contain
        max-width="34"
        max-height="31"
        src="@/assets/icons/home.png"
      ></v-img>
    </v-btn>

    <v-btn @click="doScroll('#info-origem')">
      <span class="mt-1">{{ texts.origem }}</span>
      <v-img
        contain
        max-width="35"
        max-height="28"
        src="@/assets/icons/origem.png"
      ></v-img>
    </v-btn>

    <v-btn @click="doScroll('#info-industria')">
      <span class="mt-1">{{ texts.industria }}</span>
      <v-img
        contain
        max-width="34"
        max-height="28"
        src="@/assets/icons/industria.png"
      ></v-img>
    </v-btn>
    <!-- 
    <v-btn @click="doScroll('#info-varejo')">
      <span class="mt-1">Varejo</span>
      <v-img
        contain
        max-width="28"
        max-height="28"
        src="@/assets/icons/varejo.png"
      ></v-img>
    </v-btn> -->
  </v-bottom-navigation>
</template>

<script>
export default {
  props: ['texts'],
  data() {
    return {
      optionActive: 1,
      duration: 800,
      offset: 0,
      easing: 'easeInOutCubic'
    }
  },

  created() {
    window.addEventListener('scroll', this.scrollEvent)
  },

  mounted() {
    window.scrollTo(0, 0)
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrollEvent)
  },

  methods: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      }
    },
    doScroll(target) {
      this.$vuetify.goTo(target, this.options())
    },
    goBack() {
      this.$store.state.home = true
      this.$store.state.history = false
      this.$store.state.ambiental = false
      this.$store.state.languageSelector = false
      this.$store.state.headerTitle = 'Histórico do Produto'
    },

    scrollEvent() {
      let elIndustria = document.getElementById('info-industria')
      let elVarejo = document.getElementById('info-varejo')
      let industriaOffset = elIndustria.offsetTop + 100
      let varejoOffset = elVarejo.offsetTop + 100

      switch (true) {
        case window.pageYOffset > industriaOffset &&
          window.pageYOffset < varejoOffset:
          this.optionActive = 2
          break
        case window.pageYOffset > varejoOffset:
          this.optionActive = 3
          break
        default:
          this.optionActive = 1
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#Bottom-navigation::v-deep {
  z-index: 50;
  border-radius: 25px 25px 0 0;

  button {
    width: initial;
    margin: 0 10px;

    &:first-child {
      border-radius: 25px 0 0 0;
    }

    &:last-child {
      border-radius: 0 25px 0 0;
    }
  }
}
</style>
