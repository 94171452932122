// import Vue from "vue";
import axios from "axios";
import lscache from "lscache";
import md5 from "md5";

class AppApi {
  constructor() {
    this.instance = axios.create();
    lscache.flush();
  }

  call(action, method, data, fromCache) {
    fromCache = fromCache === true ? true : false;

    let url =
      process.env.VUE_APP_API_URL + (action[0] === "/" ? "" : "/") + action;

    let keyCache = null;

    let request = {
      method: method,
      url: url,
      headers: {
        Authorization:
          "Bearer f5d508c8b28ee0f8cdb2e4ff638ec202ZkrtOdfCVFoO2FfsFtFQDLoGd65/tTE0aLGpAC0FuwSvjFkI7iJgMTQ2mptPpoXd"
        // Authorization: "Bearer " + Vue.auth.token()
      }
    };

    if (method === "GET") {
      request.params = data;
      keyCache = md5(url + JSON.stringify(data));
    } else {
      request.data = data;
    }

    return new Promise((resolve, reject) => {
      if (fromCache === true && keyCache && lscache.get(keyCache)) {
        resolve(lscache.get(keyCache));
      } else {
        this.instance
          .request(request)
          .then(result => {
            if (fromCache === true && keyCache) {
              lscache.set(keyCache, result, 5);
            }
            resolve(result);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  }

  // Chat \\
  postChatMessage(sif, data) {
    return this.call(`/industries/${sif}/feedbacks`, "POST", data);
  }
}

const appApi = new AppApi();

export default appApi;
