<template>
  <div id="InfoIndustria">
    <h3 class="my-6 secondary--text subtitle-1 font-weight-medium">
      {{ texts.dados_unidade_produtora }}
    </h3>
    <v-row class="mb-5">
      <v-col class="industry-logo-container d-flex justify-center align-center">
        <v-img
          :src="trackOrigem.industry.logo"
          max-width="135"
          max-height="63"
        ></v-img>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <TextFieldOutlined
          :label="texts.nome"
          :model="trackOrigem.industry.name"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <TextFieldOutlined
          :label="texts.cnpj"
          :model="formatCnpjCpf(trackOrigem.industry.document_number)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="trackOrigem.industry.ind_organ_number">
        <TextFieldOutlined
          label="SIF"
          :model="trackOrigem.industry.ind_organ_number"
          primary
        />
      </v-col>
      <v-col v-if="trackOrigem.lote && trackOrigem.lote != 'undefined'">
        <TextFieldOutlined
          :label="texts.lote_producao"
          :model="trackOrigem.lote"
          primary
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p class="mb-2" v-if="trackOrigem.industry.phone">
          <v-icon color="primary" size="16" class="mt-n2 mr-2"
            >fas fa-phone</v-icon
          >
          <span class="subtitle-1">{{ trackOrigem.industry.phone }}</span>
        </p>
        <p class="mb-2" v-if="trackOrigem.industry.email">
          <v-icon
            color="primary"
            size="16"
            class="mr-2"
            style="margin-top: -1px"
            >fas fa-envelope</v-icon
          >
          <span class="subtitle-1 secondary--text">{{
            trackOrigem.industry.email
          }}</span>
        </p>
        <p
          class="d-flex align-start"
          v-if="
            trackOrigem.industry.address.city_name &&
            trackOrigem.industry.address.state_initials
          "
        >
          <v-icon color="primary" size="16" class="mt-2 ml-1 mr-2"
            >fas fa-map-marker-alt</v-icon
          >
          <span class="subtitle-1"
            >{{ trackOrigem.industry.address.city_name }} -
            {{ trackOrigem.industry.address.state_initials }}
          </span>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="subtitle-1 font-weight-medium secondary--text">
          {{ texts.localizacao_unidade_produtora }}
        </p>
        <div id="mapProdutor" class="mb-7"></div>
      </v-col>
    </v-row>
    <v-row class="mb-6">
      <v-col>
        <p class="subtitle-1 font-weight-medium secondary--text text-left mb-6">
          {{ texts.planta_habilitada_exportacao }}
        </p>
        <div
          id="paises-exportacao"
          class="text-left"
          :class="!showCountries && 'contained'"
        >
          <template v-for="(pais, idx) in trackOrigem.industry.countries">
            <p :key="idx">
              <v-icon color="primary" class="mr-4">far fa-check-circle</v-icon>
              <span>{{ pais | translateCountry($route) }}</span>
            </p>
          </template>
        </div>
        <template v-if="trackOrigem.industry.countries.length > 6">
          <v-btn
            text
            color="primary"
            class="btn-underlined mt-4 ml-n4"
            v-if="!showCountries"
            @click="doList"
          >
            <span>{{ texts.ver_lista_completa }}</span>
          </v-btn>
          <v-btn
            text
            color="primary"
            class="btn-underlined mt-4"
            @click="doList"
            v-else
          >
            {{ texts.ver_lista_reduzida }}
          </v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TextFieldOutlined from '@/components/TextFieldOutlined'
import countries from '../../../countries.json'

export default {
  props: ['texts', 'track', 'trackDestino', 'trackOrigem'],
  components: {
    TextFieldOutlined
  },

  filters: {
    translateCountry(country, { query }) {
      if (!query) return country
      if (!(country in countries)) return country

      const langs = {
        'en-us': 'en',
        'en-ca': 'en',
        'es-es': 'es',
        'es-mx': 'es',
        'zh-cn': 'cn',
        zhHans: 'cn',
        cn: 'cn',
        en: 'en',
        us: 'en',
        es: 'es',
        mx: 'es'
      }

      // pt retorna como false - nao precisa traduzir
      const lang = langs[query.lg] || false
      if (!lang) return country

      const countryObj = countries[country]
      if (!countryObj) return country

      return countryObj[lang] || country
    }
  },

  data() {
    return {
      geocoder: null,
      showCountries: false
    }
  },

  mounted() {
    this.doMap()
  },

  methods: {
    formatCnpjCpf(value) {
      const cnpjCpf = value.replace(/\D/g, '')

      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/g,
          '\$1.\$2.\$3-\$4'
        )
      }

      return cnpjCpf.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '\$1.\$2.\$3/\$4-\$5'
      )
    },

    doMap() {
      this.geocoder = new google.maps.Geocoder()

      this.codeAddress(
        this.trackOrigem.industry.address.street +
          ', ' +
          this.trackOrigem.industry.address.number +
          ', ' +
          this.trackOrigem.industry.address.city_name +
          ', ' +
          this.trackOrigem.industry.address.state_name,
        (resDestino) => {
          this.printMap(resDestino)
        }
      )
    },

    codeAddress(address, callback) {
      this.geocoder.geocode({ address: address }, function (results, status) {
        if (status == 'OK') {
          var loc = results[0].geometry.location
          callback({ lat: loc.lat(), lng: loc.lng() })
        }
      })
    },

    printMap(middlePoint) {
      let map = new google.maps.Map(document.getElementById('mapProdutor'), {
        zoom: 14,
        disableDefaultUI: true,
        center: middlePoint,
        mapTypeId: 'terrain',
        zoomControl: true
      })

      var pin = new google.maps.Marker({
        position: middlePoint,
        map: map,
        title: this.trackOrigem.industry.name
      })

      pin.setMap(map)
    },

    doList() {
      this.showCountries = !this.showCountries
    },

    verCertificados() {
      this.$store.state.history = false
      this.$store.state.certificados = true
      this.$store.state.prevNavigation = 'history'
      this.$store.state.headerTitle = this.texts.certificados
    }
  }
}
</script>

<style lang="scss" scoped>
#InfoIndustria::v-deep {
  .industry-logo-container {
    background-color: #f4f8f7;
  }

  div#certificados {
    height: 105px;
    overflow-x: scroll;

    .btn-selo {
      position: relative;
      height: auto;
      padding: 15px;
      margin: 0 8px;
      background-color: #ffffff !important;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .icon-zoom {
        position: absolute;
        top: -8px;
        right: -8px;
        z-index: 10;
      }
    }
  }

  div#paises-exportacao {
    max-height: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  .btn-underlined {
    text-decoration: underline;
  }

  div#paises-exportacao.contained {
    max-height: 230px;
  }

  #mapProdutor {
    height: 171px;
  }
}
</style>
