<template>
  <div id="InfoIndustria">
    <h3 class="my-6 secondary--text subtitle-1 font-weight-medium">
      {{ texts.dados_unidade_produtora }}
    </h3>
    <v-row class="mb-5">
      <v-col class="industry-logo-container d-flex justify-center align-center">
        <v-img
          src="@/assets/images/logo-friboi.png"
          max-width="135"
          max-height="63"
        ></v-img>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <TextFieldOutlined :label="texts.nome" :model="trackOrigem.industry.name" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <TextFieldOutlined label="SIF" :model="trackOrigem.industry.ind_organ_number" primary />
      </v-col>
      <v-col v-if="trackOrigem.lote && trackOrigem.lote != 'undefined'">
        <TextFieldOutlined
          :label="texts.lote_producao"
          :model="trackOrigem.lote"
          primary
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p class="d-flex align-start">
          <v-icon color="primary" size="16" class="mt-2 ml-1 mr-2"
            >fas fa-map-marker-alt</v-icon
          >
          <span class="subtitle-1"
            >{{ trackOrigem.industry.address.city }} - {{ trackOrigem.industry.address.state }}
          </span>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="subtitle-1 font-weight-medium secondary--text">
          {{ texts.localizacao_unidade_produtora }}
        </p>
        <div id="mapProdutor" class="mb-7"></div>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col>
        <p class="subtitle-1 font-weight-medium secondary--text">
          {{ texts.certificados_unidade_produtora }}
        </p>
        <div id="certificados" class="d-flex justify-start align-center">
          <template v-for="(certificado, idx) in track.certificados">
            <v-btn
              class="btn-selo"
              depressed
              :key="idx"
              @click="verCertificados"
            >
              <v-icon color="secondary" size="11" class="icon-zoom"
                >fas fa-search-plus</v-icon
              >
              <v-img
                src="@/assets/images/sif_selo.png"
                max-width="64"
                max-height="60"
              ></v-img>
            </v-btn>
          </template>
        </div>
      </v-col>
    </v-row> -->

    <v-row class="mb-6">
      <v-col>
        <p class="subtitle-1 font-weight-medium secondary--text text-left mb-6">
          {{ texts.planta_habilitada_exportacao }}
        </p>
        <div
          id="paises-exportacao"
          class="text-left"
          :class="!showCountries && 'contained'"
        >
          <template v-for="(pais, idx) in trackOrigem.industry.countries">
            <p :key="idx">
              <v-icon color="primary" class="mr-4">far fa-check-circle</v-icon>
              <span>{{ pais.name }}</span>
            </p>
          </template>
        </div>
        <template v-if="trackOrigem.industry.countries.length > 6">
          <v-btn
            text
            color="primary"
            class="btn-underlined mt-4 ml-n4"
            v-if="!showCountries"
            @click="doList"
          >
            <span>{{ texts.ver_lista_completa }}</span>
          </v-btn>
          <v-btn
            text
            color="primary"
            class="btn-underlined mt-4"
            @click="doList"
            v-else
          >
            {{ texts.ver_lista_reduzida }}
          </v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TextFieldOutlined from "@/components/TextFieldOutlined";
export default {
  props: ["texts", "track", "trackDestino", "trackOrigem"],
  components: {
    TextFieldOutlined
  },

  data() {
    return {
      geocoder: null,
      showCountries: false
    };
  },

  mounted() {
    this.doMap();
  },

  methods: {
    doMap() {
      this.geocoder = new google.maps.Geocoder();

      this.codeAddress(
        this.trackOrigem.industry.address.city + ", " + this.trackOrigem.industry.address.state,
        resDestino => {
          this.printMap(resDestino);
        }
      );
    },

    codeAddress(address, callback) {
      this.geocoder.geocode({ address: address }, function(results, status) {
        if (status == "OK") {
          var loc = results[0].geometry.location;
          callback({ lat: loc.lat(), lng: loc.lng() });
        }
      });
    },

    printMap(middlePoint) {
      let map = new google.maps.Map(document.getElementById("mapProdutor"), {
        zoom: 13,
        disableDefaultUI: true,
        center: middlePoint,
        mapTypeId: "terrain",
        zoomControl: true
      });

      var pin = new google.maps.Marker({
        position: middlePoint,
        map: map,
        title: this.trackOrigem.industry.name
      });

      pin.setMap(map);
    },

    doList() {
      this.showCountries = !this.showCountries;
    },

    verCertificados() {
      this.$store.state.history = false;
      this.$store.state.certificados = true;
      this.$store.state.prevNavigation = "history";
      this.$store.state.headerTitle = this.texts.certificados;
    }
  }
};
</script>

<style lang="scss" scoped>
#InfoIndustria::v-deep {
  .industry-logo-container {
    background-color: #f4f8f7;
  }

  div#certificados {
    height: 105px;
    overflow-x: scroll;

    .btn-selo {
      position: relative;
      height: auto;
      padding: 15px;
      margin: 0 8px;
      background-color: #ffffff !important;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .icon-zoom {
        position: absolute;
        top: -8px;
        right: -8px;
        z-index: 10;
      }
    }
  }

  div#paises-exportacao {
    max-height: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  .btn-underlined {
    text-decoration: underline;
  }

  div#paises-exportacao.contained {
    max-height: 230px;
  }

  #mapProdutor {
    height: 171px;
  }
}
</style>
