<template>
  <div
    class="wrapper"
    :style="{
      backgroundColor: isBarcellosProduct ? '#f7f7f9' : '#3fab36'
    }"
  >
    <div v-if="!trackOrigem">
      <div v-if="!error" class="text-center mt-4">
        <v-progress-circular indeterminate color="white" />
      </div>
      <div v-else>
        <SearchPage />
      </div>
    </div>
    <div v-else id="TemplatePage">
      <BarcellosTopAppBar
        :isHome="isHome"
        :texts="texts"
        :trackOrigem="trackOrigem"
        :pageTitle="menuText"
        v-if="isBarcellosProduct"
      />
      <TopAppBar
        :isHome="isHome"
        :texts="texts"
        :trackOrigem="trackOrigem"
        :pageTitle="menuText"
        v-else
      />

      <!-- Pagina do produto -->
      <v-scroll-y-reverse-transition>
        <BarcellosHomeCard
          :isHome="isHome"
          :texts="texts"
          :track="track"
          :trackDestino="trackDestino"
          :trackOrigem="trackOrigem"
          :industry="'ecotrace'"
          transition="scroll-y-reverse-transition"
          v-if="$store.state.home && isBarcellosProduct"
        />
        <HomeCard
          :isHome="isHome"
          :texts="texts"
          :track="track"
          :trackDestino="trackDestino"
          :trackOrigem="trackOrigem"
          :industry="'ecotrace'"
          transition="scroll-y-reverse-transition"
          v-else-if="$store.state.home"
        />
      </v-scroll-y-reverse-transition>

      <!-- Pagina da origem -->
      <v-scroll-y-reverse-transition>
        <BarcellosInternCard
          :isHome="isHome"
          :texts="texts"
          :track="track"
          :trackDestino="trackDestino"
          :trackOrigem="trackOrigem"
          v-if="$store.state.history && isBarcellosProduct"
        />
        <InternCard
          :isHome="isHome"
          :texts="texts"
          :track="track"
          :trackDestino="trackDestino"
          :trackOrigem="trackOrigem"
          v-else-if="$store.state.history"
        />
      </v-scroll-y-reverse-transition>

      <!-- Pagina da Analise Ambiental -->
      <v-scroll-y-reverse-transition>
        <BarcellosAmbientalCard
          :isAmbiental="isAmbiental"
          :texts="texts"
          :track="track"
          v-if="$store.state.ambiental && isBarcellosProduct"
        />
        <AmbientalCard
          :isAmbiental="isAmbiental"
          :texts="texts"
          :track="track"
          v-else-if="$store.state.ambiental"
        />
      </v-scroll-y-reverse-transition>

      <v-scroll-y-reverse-transition>
        <SeloEcotraceCard :texts="texts" v-if="$store.state.seloEcotrace" />
      </v-scroll-y-reverse-transition>

      <!-- Fazer uma pergunta -->
      <v-scroll-y-reverse-transition>
        <ChatCard
          v-if="$store.state.chat"
          :texts="texts"
          :trackOrigem="trackOrigem"
          :track="track"
        />
      </v-scroll-y-reverse-transition>

      <v-scroll-y-reverse-transition>
        <CertificadosCard :texts="texts" v-if="$store.state.certificados" />
      </v-scroll-y-reverse-transition>
      <v-scroll-y-reverse-transition>
        <BlockChainPage :texts="texts" v-if="$store.state.blockchain" />
      </v-scroll-y-reverse-transition>
      <v-scroll-y-reverse-transition>
        <LanguageSelector :texts="texts" v-if="$store.state.languageSelector" />
      </v-scroll-y-reverse-transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

// Components
import TopAppBar from '@/components/TopAppBar'
import HomeCard from '@/components/cards/HomeCard'
import InternCard from '@/components/cards/InternCard'
import AmbientalCard from '@/components/cards/AnaliseAmbientalCard'
import SeloEcotraceCard from '@/components/cards/SeloEcotraceCard'
import ChatCard from '@/components/cards/ChatCard'
import CertificadosCard from '@/components/cards/CertificadosCard'
import SearchPage from '@/components/SearchPage'
import BlockChainPage from '@/components/BlockChainPage'
import LanguageSelector from '@/components/LanguageSelector'

import {
  BarcellosAmbientalCard,
  BarcellosHomeCard,
  BarcellosInternCard,
  BarcellosTopAppBar
} from '@/components/Barcellos'

// Languages
import langPTBR from './pt-br.ts'
import langCN from './cn.ts'
import langEN from './en.ts'
import langES from './es.ts'

export default {
  components: {
    LanguageSelector,
    BlockChainPage,
    BarcellosAmbientalCard,
    BarcellosHomeCard,
    BarcellosInternCard,
    BarcellosTopAppBar,
    TopAppBar,
    HomeCard,
    InternCard,
    AmbientalCard,
    SeloEcotraceCard,
    ChatCard,
    CertificadosCard,
    SearchPage
  },

  data() {
    return {
      error: false,
      isHome: true,
      isHistory: false,
      isAmbiental: true,
      menuText: 'Histórico do Produto',
      data: {},
      track: {
        ambiental: [
          {
            id: '1',
            api_key: 'property_deforestation_status',
            name: 'Desmatamento',
            icon: '@/assets/icons/desmatamento.png',
            status: true
          },
          {
            id: '2',
            api_key: 'supplier_slave_work_status',
            name: 'Trabalho escravo',
            status: true
          },
          {
            id: '3',
            api_key: 'property_invasion_indigenous_lands_status',
            name: 'Invasão de Terras Indígenas',
            status: true
          },
          {
            id: '4',
            api_key: 'supplier_ibama_status',
            name: 'Embargo IBAMA',
            status: true
          },
          {
            id: '5',
            api_key: 'property_invasion_enviromental_maintance_status',
            name: 'Invasão de Unidade de Conservação Ambiental',
            status: true
          }
        ]
      },
      sif: null,
      trackDestino: null,
      trackOrigem: null,
      texts: {}
    }
  },

  computed: {
    changeDataValue() {
      return this.data
    },
    isBarcellosProduct() {
      if (!this.trackOrigem) return false
      if (!this.trackOrigem.product) return false

      document.querySelector('#pageTrack').style.backgroundColor = '#f7f7f9'
      return this.trackOrigem.product.family_name === 'BARCELLOS'
    },
    lang() {
      return this.$store.state.lang ?? 'pt-br'
    }
  },

  watch: {
    lang() {
      this.switchLang(this.lang)
    }
  },

  mounted() {
    let dtprod = moment(this.$route.query.dp, 'YYYYMMDD').format('YYYY-MM-DD')
    let sku = this.$route.query.sku
    let url = `${
      process.env.VUE_APP_API_URL
    }/production-order/bovine?dt_desossa=${dtprod}&nr_lote_abate=${parseInt(
      this.$route.query.lp
    )}&ind_numero=${this.$route.query.sif}&sku=${sku}`

    const langUrl = this.$route.query.lg ?? null

    axios
      .get(url)
      .then((response) => {
        const finalLang = langUrl || this.lang || response.data.product.lang

        this.switchLang(finalLang)

        this.trackOrigem = response.data
        this.track.production_date = moment(
          this.$route.query.dp,
          'YYYYMMDD'
        ).format('YYYY-MM-DD')
        if (this.$route.query.dv) {
          this.trackOrigem.validate_date = moment(
            this.$route.query.dv,
            'YYYYMMDD'
          ).format('YYYY-MM-DD')
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.error = true
      })
  },

  methods: {
    switchLang(lang) {
      if (lang) {
        switch (lang) {
          case 'pt-br':
          case 'pt':
            this.texts = langPTBR
            this.texts['lang'] = 'pt-br'
            break
          case 'cn':
          case 'zh-cn':
          case 'zhHans':
            this.texts = langCN
            this.texts['lang'] = 'cn'
            break
          case 'en':
          case 'en-us':
          case 'en-ca':
            this.texts = langEN
            this.texts['lang'] = 'en'
            break
          case 'es':
          case 'es-es':
          case 'es-mx':
            this.texts = langES
            this.texts['lang'] = 'es'
            break
          default:
            this.texts = langPTBR
            this.texts['lang'] = 'pt-br'
            break
        }
      } else {
        this.texts = langPTBR
        this.texts['lang'] = 'pt-br'
      }
    }
  }
}
</script>
