export function goChat(trackOrigem, lg = 'pt-br') {
  if (trackOrigem) {
    switch (lg) {
      case 'pt-br':
        window.location = trackOrigem.chat['pt-br']
        break
      case 'pt':
        window.location = trackOrigem.chat['pt-br']
        break
      case 'cn':
        window.location = trackOrigem.chat['zh-cn']
        break
      case 'zh-cn':
        window.location = trackOrigem.chat['zh-cn']
        break
      case 'zhHans':
        window.location = trackOrigem.chat['zh-cn']
        break
      default:
        window.location = trackOrigem.chat['pt-br']
        break
    }
  } else {
    window.location =
      'https://ecotrace-solutions.chat.blip.ai/?appKey=ZnJpZ29saW5zdGl0dWNpb25hbDo4ZmVlZDZhYS0wYmUzLTRlOTktOWVhZi0zYjI2ODUwZDBhYzQ='
  }
}
