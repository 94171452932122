<template>
  <v-bottom-navigation fixed grow id="bottom-navigation" :value="optionActive">
    <v-btn @click="goBack">
      <span class="mt-1">{{ texts.inicio }}</span>
      <v-img
        contain
        max-width="24"
        max-height="24"
        src="@/assets/barcellos/home_icon.svg"
      />
    </v-btn>

    <v-btn @click="doScroll('#info-origem')">
      <span class="mt-1">{{ texts.origem }}</span>
      <v-img
        contain
        max-width="35"
        max-height="28"
        src="@/assets/barcellos/origin_icon.svg"
      />
    </v-btn>

    <v-btn @click="doScroll('#info-industria')">
      <span class="mt-1">{{ texts.industria }}</span>
      <v-img
        contain
        max-width="34"
        max-height="28"
        src="@/assets/barcellos/industry_icon.svg"
      />
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  props: ['texts'],
  data() {
    return {
      duration: 800,
      easing: 'easeInOutCubic',
      offset: 0,
      optionActive: 1
    }
  },

  created() {
    window.addEventListener('scroll', this.scrollEvent)
  },

  mounted() {
    window.scrollTo(0, 0)
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrollEvent)
  },

  methods: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      }
    },
    doScroll(target) {
      this.$vuetify.goTo(target, this.options())
    },
    goBack() {
      this.$store.state.home = true
      this.$store.state.history = false
      this.$store.state.ambiental = false
      this.$store.state.languageSelector = false
      this.$store.state.headerTitle = 'Histórico do Produto'
    },

    scrollEvent() {
      let elIndustria = document.getElementById('info-industria')
      let elVarejo = document.getElementById('info-varejo')
      let industriaOffset = elIndustria.offsetTop + 100
      let varejoOffset = elVarejo.offsetTop + 100

      switch (true) {
        case window.pageYOffset > industriaOffset &&
          window.pageYOffset < varejoOffset:
          this.optionActive = 2
          break
        case window.pageYOffset > varejoOffset:
          this.optionActive = 3
          break
        default:
          this.optionActive = 1
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#bottom-navigation::v-deep {
  align-items: center;
  border-radius: 25px 25px 0 0;
  display: flex;
  height: 72px !important;
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%) !important;
  max-width: 415px;
  width: 100%;
  z-index: 50;

  button {
    color: #2b2b2b;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 10px;
    filter: grayscale(1) brightness(0.32);
    margin: 0 8px;
    width: initial;

    &.v-btn--active {
      filter: grayscale(0);
      color: #f1bb00;
    }

    &:first-child {
      border-radius: 25px 0 0 0;
    }

    &:last-child {
      border-radius: 0 25px 0 0;
    }
  }
}
</style>
