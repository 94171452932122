<template>
    <svg id="SVG_steak" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="30px" height="30px" viewBox="0 0 447.046 447.046" style="enable-background:new 0 0 30 30;"
        xml:space="preserve"
    >
        <path d="M421.838,58.8C421.805,58.742,421.804,58.739,421.838,58.8L421.838,58.8z"/>
        <path d="M421.838,58.8c0.216,0.387,1.979,3.543,6.564,11.745c0.762,1.363,1.13,2.021,1.21,2.166
        C429.227,72.021,422.326,59.672,421.838,58.8z"/>
        <path d="M429.613,72.711C429.635,72.751,429.637,72.752,429.613,72.711L429.613,72.711z"/>
        <path d="M388.836,139.408c0.003-0.006,0.005-0.012,0.008-0.017c-0.004,0.008-0.01,0.018-0.014,0.026
        C388.834,139.415,388.834,139.412,388.836,139.408z"/>
        <path d="M428.403,70.545c-17.79-31.828-56.167-52.547-108.062-58.341L224.976,1.557c-22.669-2.528-45.705-2.005-68.206,1.774
        c-19.549,3.283-38.717,9.037-56.688,17.428c-16.839,7.862-32.641,18.058-46.383,30.596C40.6,63.304,29.422,77.391,21.226,93.135
        c-4.928,9.467-9.126,19.552-11.453,29.995c-1.739,7.811-2.274,15.915-0.758,23.808c1.287,6.698,4.011,12.977,7.341,18.897
        c2.648,4.707,5.737,9.148,8.354,13.872c2.291,4.136,4.144,8.505,5.188,13.128c1.306,5.784,1.354,11.771,0.646,17.64
        c-1.827,15.188-7.918,29.801-14.38,43.526l-0.089,0.189c-4.373,9.53-6.557,19.429-6.563,29.299l0.007,72.78
        c-0.057,21.125,10.364,40.643,25.387,55.022c13.984,13.389,31.637,22.58,50.019,28.317c9.784,3.054,19.86,5.149,30.033,6.379
        l0.388,0.042c22.955,2.353,46.36,0.532,68.829-4.606c20.481-4.686,40.465-12.196,58.573-22.907
        c15.604-9.23,30.229-21.046,40.575-36.085l40.848-62.52c0,0,110.353-73.891,114.698-129.786l0.051-79.736
        C438.729,96.247,435.226,82.752,428.403,70.545z M395.573,116.91c-0.83,7.824-3.391,15.39-6.729,22.48
        c1.433-3.042,6.867-14.578-0.008,0.017c-0.465,0.984-0.37,0.785-0.006,0.009c-2.18,4.623-5.462,8.861-8.681,12.789
        c-5.983,7.302-12.929,13.834-20.577,19.367c-6.126,4.432-12.795,7.973-19.345,11.72c-5.426,3.104-10.802,6.299-16.03,9.726
        c-12.726,8.341-24.452,18.101-34.752,29.312c-14.043,15.287-25.359,32.896-34.646,51.417c-1.945,3.882-3.63,7.889-6.089,11.484
        c-1.998,2.923-4.321,5.619-6.817,8.127c-5.983,6.016-12.96,11.018-20.252,15.32c-18.238,10.762-38.924,17.482-59.808,20.664
        c-22.98,3.501-46.965,2.926-68.978-5.105c-13.765-5.021-28.071-13.35-35.831-26.241c-2.846-4.729-4.605-10.133-4.317-15.693
        c0.329-6.339,3.48-11.821,5.969-17.509c4.662-10.663,8.701-21.64,11.528-32.939c4.852-19.395,5.863-39.889-1.316-58.858
        c-3.021-7.983-7.367-15.256-11.534-22.669c-2.549-4.535-4.419-9.368-4.577-14.623c-0.164-5.441,1.413-10.749,3.491-15.722
        c6.817-16.313,18.313-30.442,31.951-41.565c14.581-11.893,31.666-20.524,49.542-26.253c19.999-6.411,41.101-9.252,62.079-8.971
        c6.795,0.091,13.587,0.517,20.341,1.271l95.364,10.648c19.856,2.217,40.55,7.101,57.417,18.272
        c6.357,4.211,12.169,9.446,16.337,15.881C394.625,97.486,396.602,107.221,395.573,116.91z"/>

        <path d="M306.838,120.166c-1.392-0.065-2.905-0.064-4.556-0.086c-5.688-0.256-11.384-0.101-17.075-0.16
            c-6.106-0.063-12.222,0.098-18.328,0.16c-12.553,0.127-25.101-0.104-37.646-0.54c-6.452-0.548-12.914-0.814-19.357-1.51
            c-6.33-0.684-12.61-1.673-18.872-2.811c-12.301-2.234-24.403-5.771-35.965-10.525c-7.006-2.881-14.043-6.24-20.348-10.472
            c0,0.75,0.338,1.619,0.535,2.33c0.313,1.12,0.692,2.222,1.111,3.307c0.982,2.545,2.185,5.007,3.56,7.364
            c3.009,5.161,6.694,9.833,10.886,14.082c8.354,8.474,18.778,15.231,29.812,19.678c4.188,1.688,8.449,3.209,12.802,4.418
            c-4.41,23.471-11.124,46.43-19.519,68.772l-1.508,3.991c-6.074,16.236-13.97,31.702-22.059,47.001
            c-3.587,6.083-7.038,12.271-10.941,18.158c-2.665,4.02-5.329,8.039-7.994,12.059c3.199-3.46,6.397-6.922,9.597-10.384
            c4.938-5.345,9.471-11.054,14.121-16.646c10.733-14.209,21.254-28.625,30.125-44.099l2.211-3.84
            c12.484-21.822,23.336-44.547,31.928-68.189c0.306-0.838,0.604-1.677,0.908-2.515c6.407,0.204,12.804,0.291,19.197-0.29
            c6.368-0.579,12.708-1.46,18.967-2.785c12.198-2.583,24.251-6.714,35.409-12.285c7.653-3.821,15.193-8.189,21.848-13.6
            C315.687,120.469,307.416,120.182,306.838,120.166C305.448,120.101,309.605,120.242,306.838,120.166z"/>
    </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>