export default {
    frigorifico : '屠宰场',
    dt_producao : '生产日期',
    cnpj : '公司税号',
    email : '电邮',
    telefone : '电话',
    fazenda : '农场',
    cidade : '城市',
    origem : '原产地'
}
