export default {
  inicio: 'Início',
  origem: 'Origem',
  industria: 'Indústria',
  dt_producao: 'Data de produção',
  dt_validade: 'Data de validade',
  dt_recebimento: 'Data de recebimento',
  codigo_rastreio: 'Código de rastreio',
  tipo_corte: 'Tipo de corte',
  lote_producao: 'Lote de produção',
  nome: 'Nome',
  cnpj: 'CNPJ',
  email: 'E-mail',
  telefone: 'Telefone',
  fazenda: 'Fazenda',
  cidade: 'Cidade',
  historico_produto: 'Histórico do produto',
  dados_produto: 'Dados do produto',
  info_origem: 'Informações da origem - Biomas',
  ver_mapa: 'Ver no mapa',
  analise_ambiental: 'Análise Ambiental',
  certificados: 'Certificados',
  blockchain: 'Blockchain',
  contato: 'Contato',
  selo_ecotrace: 'Selo Ecotrace',
  info_industria: 'Informações da indústria',
  dados_unidade_produtora: 'Dados da unidade produtora',
  localizacao_unidade_produtora: 'Localização da unidade produtora',
  certificados_unidade_produtora: 'Certificados da unidade produtora',
  planta_habilitada_exportacao: 'Planta habilitada para exportação',
  ver_lista_completa: 'Ver lista completa',
  ver_lista_reduzida: 'Ver lista reduzida',
  info_varejo: 'Assistente Virtual',
  botao_chatbot: 'Gostaria de falar com nosso assistente virtual?',
  localizacao_varejo: 'Localização do varejo',
  ajuda_sobre_produto: 'Precisa de ajuda sobre esse produto?',
  fale_com_barcellos: 'Fale com a Barcellos',
  fazer_pergunta: 'Fazer pergunta',
  propriedade_conforme: 'Propriedade 100% APTA!',
  nada_consta: 'Nada Consta',
  certificados_title: 'SIF - REGISTRO DE ESTABELECIMENTOS',
  certificados_text:
    'O registro de estabelecimentos junto ao Serviço de Inspeção Federal – SIF é obrigatório para todas as indústrias de produtos de origem animal: carne e derivados, leite e derivados, pescados, mel e ovos; que pretendem comercializar seus produtos fora das fronteiras estaduais ou federal.',
  blockchain_title: 'Dados protegidos por blockchain',
  blockchain_text:
    'A blockchain é uma tecnologia de registro distribuído que visa a descentralização como medida de segurança. São bases de registros e dados distribuídos e compartilhados que têm a função de criar um índice global para todas as transações que ocorrem em um determinado mercado.',
  seloecotrace_title: 'Dados protegidos por blockchain',
  seloecotrace_text:
    'A blockchain é uma tecnologia de registro distribuído que visa a descentralização como medida de segurança. São bases de registros e dados distribuídos e compartilhados que têm a função de criar um índice global para todas as transações que ocorrem em um determinado mercado.',
  deixe_mensagem: 'Deixe sua mensagem',
  mensagem_header_text:
    'Se identificar qualquer problema com este produto ou tiver alguma dúvida, por favor deixe-nos saber. Responderemos o mais breve possível.',
  enviar_minha_duvida: 'Enviar minha dúvida',
  produto_rastreabilidade: 'Produto com rastreabilidade de produção',
  consulta_dados_historico_ecotrace:
    'Agora você pode consultar todos dos dados do histórico de produção deste produto com Tecnologia',
  dados_disponiveis_breve: 'Os dados estarão disponíveis em breve',
  rastreabilidade_da_producao: 'Rastreabilidade da produção',
  dados_disponiveis_em:
    'Os dados deste produto estão em processamento e estarão disponíveis em',
  produto_frigol: 'Produto frigol',

  propriedade_nao_conforme: 'Propriedade não APTA!',
  analise_status_conforme: 'Conforme',
  analise_status_nao_conforme: 'Não Conforme',
  propriedade_com_inconsistencia: 'Propriedade com Inconsistência!'
}
