<template>
  <div id="pageTrack">
    <v-container fluid>
      <TopAppBar :isHome="true" :texts="texts" v-if="!frigorifico" />
      <v-scroll-y-reverse-transition>
        <SearchPage v-if="!frigorifico && $store.state.home" />
      </v-scroll-y-reverse-transition>

      <EcotraceTemplate v-if="frigorifico == 'ecotrace'" />
      <TemplateSeara v-if="frigorifico == 'seara'" />
      <TemplateFrigol v-if="frigorifico == 'frigol'" />
      <TemplateFriboi v-if="frigorifico == 'friboi'" />
    </v-container>
  </div>
</template>

<script>
import langPTBR from '@/templates/ecotrace/pt-br.ts'
import langCN from '@/templates/ecotrace/cn.ts'

import TopAppBar from '@/components/TopAppBar'

import mockDataSifs from '../assets/dataMockSifs'

import EcotraceTemplate from '../templates/ecotrace/EcotraceTemplate'
import TemplateSeara from '../templates/seara/SearaTemplate'
import TemplateFrigol from '../templates/frigol/FrigolTemplate'
import TemplateFriboi from '../templates/friboi/FriboiTemplate'

import SearchPage from './SearchPage'

export default {
  components: {
    TopAppBar,
    EcotraceTemplate,
    TemplateSeara,
    TemplateFrigol,
    TemplateFriboi,
    SearchPage
  },

  data: function () {
    return {
      frigorifico: '',
      texts: {}
    }
  },

  created() {
    this.texts = langPTBR

    if (this.$route.query.sif) {
      const marcaFind = mockDataSifs.empresas.find(
        (x) => x.sif === this.$route.query.sif
      )
      if (marcaFind) this.frigorifico = marcaFind.brand

      if (!this.frigorifico && this.$route.query.sif == '0042') {
        window.location.reload()
      }
    }
  },

  mounted() {
    if (this.$route.query.lg) {
      switch (this.$route.query.lg) {
        case 'pt-br':
        case 'pt':
          this.texts = langPTBR
          break
        case 'cn':
        case 'zh-cn':
        case 'zhHans':
          this.texts = langCN
          break
        default:
          this.texts = langPTBR
          break
      }
    } else {
      this.texts = langPTBR
    }
  }
}
</script>
<style lang="scss" scoped>
#pageTrack::v-deep {
  background-color: $primary-color;
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
  min-height: 100vh;
  overflow: hidden;
  width: 420px;

  .container {
    padding: 0;
  }

  .top {
    background-color: #fff;
    padding: 20px;
  }

  .content {
    background-color: #fff;
    padding: 20px;
  }

  .error-header-container {
    background-color: #d32f2f;
    box-shadow: none;

    h2 {
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      font-weight: bold;
    }
  }

  .error-card-text h2 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
}
</style>
