<template>
  <header id="header">
    <nav class="top-navbar">
      <v-row no-gutters class="align-center">
        <v-col cols="8" class="d-flex justify-space-around align-center">
          <v-btn
            text
            icon
            color="white"
            @click="goBack"
            v-if="!$store.state.home && !$store.state.origem"
          >
            <v-icon size="16">fas fa-chevron-left</v-icon>
          </v-btn>
          <h2 class="title white--text">
            {{ $store.state.headerTitle }}
          </h2>
        </v-col>
        <v-col cols="4" class="d-flex justify-center align-center">
          <v-btn
            id="btn-ajuda"
            dark
            class="help-button mr-2"
            color="#81C683"
            @click="goChat"
          >
            <v-img
              src="@/assets/icons/chatbot-branco.png"
              max-width="27"
              max-height="27"
              contain
            ></v-img>
          </v-btn>
          <v-btn
            id="btn-blockchain"
            dark
            class="help-button"
            color="#81C683"
            @click="goBlockChain"
          >
            <v-img
              src="@/assets/icons/icone-blockchain.png"
              max-width="27"
              max-height="27"
              contain
            ></v-img>
          </v-btn>
        </v-col>
      </v-row>
    </nav>
  </header>
</template>

<script>
export default {
  props: ['texts', 'trackOrigem'],
  methods: {
    goBack() {
      if (this.$store.state.prevNavigation == 'home') {
        this.$store.state.home = true
        this.$store.state.history = false
        this.$store.state.ambiental = false
        this.$store.state.blockchain = false
        this.$store.state.certificados = false
        this.$store.state.languageSelector = false
        this.$store.state.chat = false
        this.$store.state.headerTitle = 'Dados do Produto'
        this.$store.state.headerTitle = this.texts.dados_produto
      } else {
        this.$store.state.home = false
        this.$store.state.history = true
        this.$store.state.ambiental = false
        this.$store.state.blockchain = false
        this.$store.state.certificados = false
        this.$store.state.languageSelector = false
        this.$store.state.chat = false
        this.$store.state.prevNavigation = 'home'
        this.$store.state.headerTitle = this.texts.historico_produto
      }
    },

    goBlockChain() {
      this.$store.state.blockchain = true
      this.$store.state.home = false
      this.$store.state.history = false
      this.$store.state.ambiental = false
      this.$store.state.languageSelector = false
      this.$store.state.seloEcotrace = false
      this.$store.state.chat = false
      this.$store.state.headerTitle = this.texts.blockchain
    },

    goChat() {
      switch (this.$route.query.lg) {
        case 'pt-br':
          window.location = this.trackOrigem.chat['pt-br']
          break
        case 'pt':
          window.location = this.trackOrigem.chat['pt-br']
          break
        case 'cn':
          window.location = this.trackOrigem.chat['zh-cn']
          break
        case 'zh-cn':
          window.location = this.trackOrigem.chat['zh-cn']
          break
        case 'zhHans':
          window.location = this.trackOrigem.chat['zh-cn']
          break
        default:
          window.location = this.trackOrigem.chat['pt-br']
          break
      }
      // this.$store.state.blockchain = false;
      // this.$store.state.home = false;
      // this.$store.state.history = false;
      // this.$store.state.ambiental = false;
      // this.$store.state.seloEcotrace = false;
      // this.$store.state.chat = true;
      // this.$store.state.headerTitle = "Contato";
    }
  }
}
</script>

<style lang="scss" scoped>
#header::v-deep {
  padding: 20px 12px;
  margin-bottom: 20px;

  .top-navbar {
    .col {
      &:nth-child(2) {
        @media screen and (max-width: 400px) {
          max-width: 50%;
        }
      }

      .help-button {
        display: block;
        min-width: inherit;
        height: 42px;
        width: 42px;
        max-width: 42px;
        max-height: 42px;
        padding: 0;
        border-radius: 50%;
        box-shadow: 0px 3px 29px #0000000d;

        span.v-btn__content {
          display: flex;
          flex-direction: column !important;

          .btn-text {
            margin-top: 3px;
            font-size: 11px;
            letter-spacing: 0;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
</style>
