<template>
  <div id="InfoVarejo">
    <!-- <v-row class="mb-5 mt-9">
      <v-col class="varejo-logo-container d-flex justify-center align-center">
        <v-img
          src="@/assets/images/varejo.png"
          max-width="135"
          max-height="63"
        ></v-img>
      </v-col>
    </v-row> -->

    <v-row class="mb-5 mt-9">
      <v-col class="varejo-logo-container d-flex justify-center align-center">
        <v-btn
          id="btn_chatbot"
          @click="goChat()"
          depressed
          class="py-4"
          color="secondary"
        >
          <v-img
            src="@/assets/icons/chatbot-branco.png"
            max-width="60"
            max-height="60"
            class="mr-3"
          />
          <span class="btn-text text-left white--text">{{
            texts.botao_chatbot
          }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <!-- <v-row class="mb-5 mt-9">
      <v-col class="varejo-logo-container d-flex justify-center align-center">
        <v-btn @click="goChat()" depressed class="py-4" color="#EF5350">
          <v-img
            src="@/assets/images/chatbot_icon.png"
            max-width="60"
            max-height="60"
            class="mr-3"
          />
          <span class="text-left white--text"
            >Gostaria de falar com nosso <br />assistente virtual?</span
          >
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-5 mt-9">
      <v-col class="varejo-logo-container d-flex justify-center align-center">
        <v-btn @click="goChat()" depressed class="py-4" color="secondary">
          <v-img
            src="@/assets/images/chatbot_icon_2.png"
            max-width="60"
            max-height="60"
            class="mr-3"
          />
          <span class="text-left white--text"
            >Gostaria de falar com nosso <br />assistente virtual?</span
          >
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-5 mt-9">
      <v-col class="varejo-logo-container d-flex justify-center align-center">
        <v-btn @click="goChat()" depressed class="py-4" color="#EF5350">
          <v-img
            src="@/assets/images/chatbot_icon_2.png"
            max-width="60"
            max-height="60"
            class="mr-3"
          />
          <span class="text-left white--text"
            >Gostaria de falar com nosso <br />assistente virtual?</span
          >
        </v-btn>
      </v-col>
    </v-row> -->
    <!-- <v-row>
      <v-col>
        <TextFieldOutlined :label="texts.nome" :model="varejo.name" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TextFieldOutlined
          :label="texts.cnpj"
          :model="varejo.document_number"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="container-data-recebimento">
        <p>
          {{ texts.dt_recebimento }}
          <span class="primary--text font-weight-medium">{{
            varejo.dt_recebimento | formatDate
          }}</span>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="mb-2">
          <v-icon color="primary" size="16" class="mt-n2 mr-2"
            >fas fa-phone</v-icon
          >
          <span class="subtitle-1">{{ varejo.phone }}</span>
        </p>
        <p class="mb-2">
          <v-icon
            color="primary"
            size="16"
            class="mr-2"
            style="margin-top: -1px"
            >fas fa-envelope</v-icon
          >
          <span class="subtitle-1 secondary--text">{{ varejo.email }}</span>
        </p>
        <p class="d-flex align-start">
          <v-icon color="primary" size="16" class="mt-2 ml-1 mr-2"
            >fas fa-map-marker-alt</v-icon
          >
          <span class="subtitle-1">{{ varejo.address }} </span>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="secondary--text subtitle-1 font-weight-medium">
          {{ texts.localizacao_varejo }}
        </p>
        <div id="mapVarejo"></div>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import TextFieldOutlined from '@/components/TextFieldOutlined'
export default {
  props: ['texts', 'track', 'trackOrigem'],
  components: {
    TextFieldOutlined
  },

  data() {
    return {
      varejo: null,
      geocoder: null
    }
  },

  created() {
    this.varejo = this.track.varejo

    // console.log(this.varejo);
  },

  mounted() {
    //this.doMap();
  },

  methods: {
    doMap() {
      // this.geocoder = new google.maps.Geocoder();
      // this.codeAddress(this.varejo.address, resDestino => {
      //   this.printMap(resDestino);
      // });
    },

    codeAddress(address, callback) {
      this.geocoder.geocode({ address: address }, function(results, status) {
        if (status == 'OK') {
          var loc = results[0].geometry.location
          callback({ lat: loc.lat(), lng: loc.lng() })
        }
      })
    },

    printMap(middlePoint) {
      let map = new google.maps.Map(document.getElementById('mapVarejo'), {
        zoom: 16,
        disableDefaultUI: true,
        center: middlePoint,
        mapTypeId: 'terrain',
        zoomControl: true
      })

      var pin = new google.maps.Marker({
        position: middlePoint,
        map: map
        //title: this.varejo.name
      })

      pin.setMap(map)
    },

    goChat() {
      switch (this.$route.query.lg) {
        case 'pt-br':
          window.location = this.trackOrigem.chat['pt-br']
          break
        case 'pt':
          window.location = this.trackOrigem.chat['pt-br']
          break
        case 'cn':
          window.location = this.trackOrigem.chat['zh-cn']
          break
        case 'zh-cn':
          window.location = this.trackOrigem.chat['zh-cn']
          break
        case 'zhHans':
          window.location = this.trackOrigem.chat['zh-cn']
          break
        default:
          window.location = this.trackOrigem.chat['pt-br']
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#InfoVarejo::v-deep {
  .varejo-logo-container {
    padding: 0px 0px;

    button {
      height: auto;
      border-radius: 12px;

      span.btn-text {
        display: block;
        max-width: 230px;
        white-space: normal;

        @media screen and (max-width: 450px) {
          max-width: 40%;
        }

        @media screen and (max-width: 350px) {
          max-width: 30%;
          font-size: 11px;
        }
      }
    }
  }

  .container-data-recebimento {
    p {
      margin: 0;
      padding: 16px 19px;
      font-size: 17px;
      background-color: #f4f8f7;
    }
  }

  #mapVarejo {
    height: 171px;
  }
}
</style>
