<template>
  <div>
    <div v-if="!showSearchPage">
      <v-card id="HomeCard" class="Ecotrace-card">
        <v-card-text v-if="!isProgrammatic" class="px-6">
          <v-row no-gutters>
            <v-col cols="8" class="d-flex justify-center align-center">
              <v-img
                max-height="224"
                max-width="198"
                :src="trackOrigem.product.image_url"
                v-if="haveProduct && trackOrigem.product.image_url"
                contain
              />
              <v-img
                max-height="224"
                max-width="198"
                src="@/assets/images/generic-product.svg"
                contain
                v-else
              />
            </v-col>
            <v-col
              cols="4"
              class="d-flex flex-column justify-center align-center"
            >
              <v-btn
                id="btn_selo_ecotrace"
                color="white"
                class="mb-2"
                fab
                dark
                @click="seloEcotracePage"
              >
                <v-img
                  contain
                  max-height="84"
                  max-width="100"
                  src="@/assets/icons/selo.png"
                />
              </v-btn>
              <v-col class="tipo-preparo d-flex justify-space-around align-center">
                <div v-if="trackOrigem.product && trackOrigem.product.preparation.toCook" id="btn_panela"
                  class="my-3 btn-product">
                  <v-img contain height="45" src="@/assets/icons/panela.svg" />
                </div>
                <div v-if="trackOrigem.product && trackOrigem.product.preparation.toGrill" id="btn_churrasco"
                  color="white" class="my-3 btn-product">
                  <v-img contain max-height="45" src="@/assets/icons/churrasco.svg" />
                </div>
              </v-col>
              <v-col class="tipo-preparo d-flex justify-space-around align-center">
                <div v-if="trackOrigem.product && trackOrigem.product.preparation.toFry" id="btn_churrasco" color="white"
                  class="my-3 btn-product">
                  <v-img contain height="45" src="@/assets/icons/fritar.svg" />
                </div>
                <div v-if="trackOrigem.product && trackOrigem.product.preparation.toRoast" id="btn_churrasco" color="white"
                  class="my-3 btn-product">
                  <v-img contain height="45" src="@/assets/icons/assar.svg" />
                </div>
              </v-col>
            </v-col>
          </v-row>
          <v-row v-if="!haveProduct">
            <v-col>
              <h2 class="secondary--text font-weight-medium text-uppercase">
                {{ texts.produto_frigol }}
              </h2>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <h2 class="headline secondary--text font-weight-medium">
                {{ getProductName(trackOrigem.product) | firstLetterEachWord }}
              </h2>
            </v-col>
          </v-row>
          <hr />
          <v-row v-if="!haveProduct">
            <v-col>
              <div class="brand-logo-container">
                <v-img
                  :src="trackOrigem.industry.logo"
                  max-width="117"
                  max-height="55"
                  contain
                />
              </div>
            </v-col>
          </v-row>
          <v-row class="infos-container">
            <v-col :cols="haveProduct ? 7 : 12">
              <label>{{ texts.industria }}</label>
              <span class="secondary--text">
                {{ trackOrigem.industry.name }}
              </span>
            </v-col>
            <v-col
              v-if="haveProduct"
              cols="5"
              class="d-flex justify-center align-center"
            >
              <v-img
                :src="trackOrigem.industry.logo"
                max-width="97"
                max-height="46"
                contain
              />
            </v-col>
          </v-row>

          <v-row class="infos-container">
            <v-col v-if="trackOrigem.product && trackOrigem.product.hash">
              <label>{{ texts.codigo_rastreio }}</label>
              <span class="primary--text">
                {{ trackOrigem.product.hash }}
              </span>
            </v-col>
          </v-row>
          <hr />
          <v-row class="infos-container">
            <v-col>
              <label>{{ texts.dt_producao }}</label>
              <span class="secondary--text">
                {{ track.production_date | formatDate }}
              </span>
            </v-col>
            <v-col v-if="trackOrigem.product && trackOrigem.product.due_days">
              <label>{{ texts.dt_validade }}</label>
              <span class="primary--text">
                {{ getDueDate() | formatDate }}
              </span>
            </v-col>
          </v-row>
          <hr />
          <v-row
            v-if="haveProduct && trackOrigem.product.product_part"
            class="infos-container align-center"
          >
            <v-col>
              <label>{{ texts.tipo_corte }}</label>
              <span class="secondary--text">
                {{
                   trackOrigem.product.product_part_name ? trackOrigem.product.product_part_name.split('-')[0] : firstLetterEachWord
                }}
              </span>
            </v-col>
            <v-col>
              <v-img
                contain
                :src="
                  require(`@/assets/icons/types/${trackOrigem.product.product_part}.png`)
                "
                max-width="248"
                max-height="200"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                class="btn-historico-produto"
                color="secondary"
                height="59"
                id="btn_historico_produto"
                width="100%"
                dark
                @click="internPage"
              >
                {{ texts.historico_produto }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-col cols="12" class="d-flex justify-center align-center pt-7 pb-4">
            <v-img
              contain
              max-width="153"
              max-height="180"
              src="@/assets/images/man-time-phone.svg"
            />
          </v-col>
          <v-col cols="12">
            <h2
              class="primary--text text-h5 font-weight-medium text-center"
              style="font-size: 26px; line-height: 28px; padding: 0 4vw;"
            >
              {{ texts.dados_disponiveis_breve }}
            </h2>
          </v-col>
          <v-col cols="12">
            <InfoCard
              :text="this.texts.dados_disponiveis_em"
              :extraText="infoTextDate()"
              class="mb-3"
            />
          </v-col>
          <v-col cols="12">
            <h2
              class="secondary--text font-weight-medium"
              style="font-size: 22px; line-height: 22px;"
            >
              {{ texts.rastreabilidade_da_producao }}
            </h2>
          </v-col>
          <v-row class="px-3">
            <v-col cols="7">
              <span class="label-text">{{ texts.industria }}</span>
              <p class="data-text">{{ trackOrigem.industry.name }}</p>
            </v-col>
            <v-col cols="5" class="d-flex justify-end">
              <v-img
                :src="trackOrigem.industry.logo"
                contain
                max-height="50"
                max-width="100"
              />
            </v-col>
            <v-col cols="12">
              <hr class="separator" />
            </v-col>
            <v-col cols="12">
              <span class="label-text">{{ texts.dt_producao }}</span>
              <p class="data-text">{{ dateProduction | formatDate }}</p>
            </v-col>
          </v-row>

          <v-col cols="12">
            <v-btn
              width="100%"
              height="59"
              dark
              color="secondary"
              @click="goSearchPage()"
              class="mt-3"
            >
              Buscar outro produto
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </div>
    <SearchPage v-else />
  </div>
</template>

<script>
import moment from 'moment'

import InfoCard from '@/components/cards/InfoTextCard'
import SearchPage from '@/components/SearchPage'

export default {
  components: { InfoCard, SearchPage },

  props: [
    'isHome',
    'texts',
    'track',
    'trackDestino',
    'trackOrigem',
    'industry'
  ],

  data() {
    return {
      isProgrammatic: false,
      infoDate: null,
      haveProduct: false,
      dateProduction: null,
      showSearchPage: false
    }
  },

  created() {
    this.$store.state.headerTitle = this.texts.dados_produto
  },

  mounted() {
    const todayDate = moment().format('YYYY-MM-DD')
    this.dateProduction = moment(this.$route.query.dp).format('YYYY-MM-DD')

    if (!this.trackOrigem || !this.trackOrigem.data) {
      this.checkDateHigher(todayDate, this.dateProduction)
    }

    if (this.isProgrammatic == false && !this.trackOrigem.environmental) {
      this.showSearchPage = true
      return
    }

    if (this.trackOrigem && this.trackOrigem.product) {
      this.haveProduct = true
      this.$store.state.menu_logo = false
    } else {
      this.haveProduct = false
      this.$store.state.menu_logo = true
    }

    window.scrollTo(0, 0)
  },

  methods: {
    getDueDate() {
      return moment(this.track.production_date)
        .add('days', this.trackOrigem.product.due_days)
        .format('YYYY-MM-DD')
    },

    infoTextDate() {
      let text = moment(this.track.production_date)
        .add(1, 'days')
        .format('DD/MM/YYYY')
        .toString()
      return text
    },

    checkDateHigher(date1, date2) {
      if (moment(date2).isSameOrAfter(moment(date1))) {
        this.$store.state.menu_logo = true
        this.isProgrammatic = true
      } else {
        this.$store.state.menu_logo = false
        this.isProgrammatic = false
      }
    },

    internPage() {
      this.$store.state.home = false
      this.$store.state.history = true
      this.$store.state.prevNavigation = 'home'
      this.$store.state.headerTitle = this.texts.historico_produto
    },

    seloEcotracePage() {
      this.$store.state.home = false
      this.$store.state.seloEcotrace = true
      this.$store.state.prevNavigation = 'home'
      this.$store.state.headerTitle = this.texts.selo_ecotrace
    },

    getProductName(product) {
      if (product.languages) {
        for (let i = 0; i < product.languages.length; i++) {
          let lg = product.languages[i]
          if (lg.language == this.texts.globalLang) return lg.name
        }
      }

      return product.name
    },

    goSearchPage() {
      window.location.href = '/'
    }
  }
}
</script>

<style lang="scss" scoped>
.Ecotrace-card {
  padding-bottom: 20px;

  #btn_selo_ecotrace {
    width: 110px;
    height: 95px;
    box-shadow: none;
    margin: 0 50px;
  }

  .tipo-preparo{
    padding: 0;
  }

  .btn-product {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    box-shadow: 0px 3px 15px #0000001a;
  }

  hr {
    border: 1px solid $tertiary-color;
  }

  .infos-container {
    padding: 0.5rem 0;

    label {
      display: block;
      font-size: 18px;
    }
    span {
      font-size: 18px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  .btn-historico-produto {
    font-size: 16px;
    border-radius: 12px;
    box-shadow: 0px 17px 31px #00000024;
  }

  .label-text {
    font-size: 18px;
    line-height: 22px;
    color: #6a707e;
  }

  .data-text {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: $secondary-color;
    margin: 0;
  }

  .separator {
    border: 0;
    border-bottom: 1px solid #f2f3f7;
  }

  .brand-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 20px 0;
    background-color: #f4f8f7;
    overflow: hidden;
    box-shadow: 0px 3px 0px #0000000a;
  }
}

.btn-historico-produto {
  margin: 1rem 0 0 0;
}
</style>
