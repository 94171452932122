import { render, staticRenderFns } from "./FrigolTemplate.vue?vue&type=template&id=6905cd6a&scoped=true&"
import script from "./FrigolTemplate.vue?vue&type=script&lang=js&"
export * from "./FrigolTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6905cd6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VScrollYReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VScrollYReverseTransition})
