<template>
  <div id="TemplatePage">
    <TopAppBar :isHome="isHome" :texts="texts" :pageTitle="menuTextValue" />
    <v-scroll-y-reverse-transition>
      <HomeCard
        :isHome="isHome"
        :texts="texts"
        :track="track"
        :trackDestino="trackDestino"
        :industry="'frigol'"
        transition="scroll-y-reverse-transition"
        v-if="$store.state.home"
      />
    </v-scroll-y-reverse-transition>
    <v-scroll-y-reverse-transition>
      <InternCard
        :isHome="isHome"
        :texts="texts"
        :track="track"
        :trackDestino="trackDestino"
        :trackOrigem="trackOrigem"
        v-if="$store.state.history"
      />
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <AmbientalCard
        :isAmbiental="isAmbiental"
        :texts="texts"
        :track="track"
        v-if="$store.state.ambiental"
      />
    </v-scroll-y-reverse-transition>
    <v-scroll-y-reverse-transition>
      <BlockChainCard :texts="texts" v-if="$store.state.blockchain" />
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <SeloEcotraceCard :texts="texts" v-if="$store.state.seloEcotrace" />
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <ChatCard v-if="$store.state.chat" />
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <CertificadosCard v-if="$store.state.certificados" />
    </v-scroll-y-reverse-transition>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import mockDataFrigol from '../../assets/dataMockFrigol'

// Components //
import TopAppBar from '@/components/TopAppBar'
import HomeCard from '@/components/cards/HomeCard'
import InternCard from '@/components/cards/InternCard'
import AmbientalCard from '@/components/cards/AnaliseAmbientalCard'
import BlockChainCard from '@/components/cards/BlockChainCard'
import SeloEcotraceCard from '@/components/cards/SeloEcotraceCard'
import ChatCard from '@/components/cards/ChatCard'
import CertificadosCard from '@/components/cards/CertificadosCard'

// Languages //
import langPTBR from './pt-br.ts'
import langCN from './cn.ts'
import langEN from './en.ts'
import langES from './es.ts'

export default {
  components: {
    TopAppBar,
    HomeCard,
    InternCard,
    AmbientalCard,
    BlockChainCard,
    SeloEcotraceCard,
    ChatCard,
    CertificadosCard
  },

  data() {
    return {
      isHome: true,
      isHistory: false,
      isAmbiental: false,
      menuText: 'Histórico do Produto',
      data: {},
      track: {},
      sif: null,
      trackDestino: null,
      trackOrigem: null,
      texts: {}
    }
  },

  computed: {
    changeDataValue() {
      return this.data
    },

    menuTextValue() {
      return this.menuText
    }
  },

  created() {
    this.track = mockDataFrigol
    this.trackDestino = mockDataFrigol.destiny.find(
      (x) => x.sif === this.$route.query.sif
    )
    this.sif = this.trackDestino.sif

    if (this.track && this.trackDestino) {
      if (this.$route.query.lg) {
        switch (this.$route.query.lg) {
          case 'pt-br':
          case 'pt':
            this.texts = langPTBR
            break
          case 'cn':
          case 'zh-cn':
          case 'zhHans':
            this.texts = langCN
            break
          case 'en':
          case 'en-us':
          case 'en-ca':
            this.texts = langEN
            break
          case 'es':
          case 'es-es':
          case 'es-mx':
            this.texts = langES
            break
          default:
            this.texts = langPTBR
            break
        }
      } else {
        this.texts = langPTBR
      }
    }
  },

  mounted() {
    let dtprod = moment(this.$route.query.dp, 'YYYYMMDD').format('YYYY-MM-DD')
    let sku = this.$route.query.sku
    let url = `${process.env.VUE_APP_API_URL}/production-order/bovine?dt_desossa=${dtprod}&nr_lote_abate=${this.$route.query.lp}&ind_numero=${this.sif}&sku=${sku}`
    axios.get(url).then((response) => {
      this.trackOrigem = response.data.data
    })
  }
}
</script>

<style lang="" scoped></style>
