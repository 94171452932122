<template>
  <div class="container-infos">
    <v-row>
      <v-col class="d-flex justify-space-between align-center">
        <div class="infos">
          <p class="property_name">{{ origin.faz_nome.toLowerCase() }}</p>
          <span>
            {{ origin.faz_cidade.toLowerCase() }} -
            {{ origin.faz_estado }}
          </span>

          <div class="analysis">
            <div class="id-monitoramento" v-if="origin.cod_empresa_ambiental">
              {{ texts.id_monitoramento }}:
              <span class="id">
                {{ origin.cod_empresa_ambiental }}
              </span>
            </div>
            <v-img
              v-if="origin.analysis && origin.analysis.property_current_status"
              src="@/assets/barcellos/selo-ecotrace.svg"
              class="selo-ecotrace"
              max-width="32"
              max-height="48"
            />
          </div>

          <span class="laudo" v-if="origin.cod_empresa_ambiental">
            {{ texts.empresa_responsavel_monitoramento }}:
            <a
              class="url-laudo"
              :href="nicePlanetURL"
              ref="noreferrer; noopener"
              target="_blank"
            >
              {{ nicePlanetURL }}
            </a>
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="origin.faz_car && origin.faz_car.trim().length > 0">
      <v-col>
        CAR:
        <span
          class="d-block primary--text font-weight-bold"
          style="font-size: 11px"
        >
          {{ origin.faz_car }}
        </span>
      </v-col>
    </v-row>

    <v-row no-gutters class="btns-container">
      <parallelogram-button
        :disabled="!hasPolygon"
        variant="secondary"
        :label="texts.ver_mapa"
        :onClick="() => showMap(idx)"
      >
        <v-icon left color="#744600" size="16" slot="icon">
          fas fa-map-marker-alt
        </v-icon>
      </parallelogram-button>
      <parallelogram-button
        variant="primary"
        :label="texts.analise_ambiental"
        :onClick="() => showAmbiental(origin)"
      >
        <v-icon left color="white" size="16" slot="icon"> fas fa-leaf </v-icon>
      </parallelogram-button>
    </v-row>

    <v-row v-if="mapReference == idx">
      <v-col>
        <div :id="'map-' + idx" class="map-container"></div>
      </v-col>
    </v-row>

    <hr class="my-3" />
  </div>
</template>

<script>
import ParallelogramButton from './ParallelogramButton.vue'
export default {
  components: { ParallelogramButton },
  props: ['texts', 'idx', 'track', 'origin'],
  data() {
    return {
      geocoder: null,
      mapReference: null
    }
  },

  watch: {
    origin: {
      handler() {
        this.mapReference = null
        // do stuff
      },
      deep: true
    }
  },

  computed: {
    genAnalysisURL() {
      const __BASE_URL = 'https://public.smgeo.com.br/propriedade'
      return (codigoAnalise) => `${__BASE_URL}/${codigoAnalise}`
    },
    nicePlanetURL() {
      return 'https://niceplanet.com.br'
    },
    hasPolygon() {
      return (
        this.origin.analysis &&
        this.origin.analysis?.polygon?.coordinates &&
        this.origin.analysis.polygon.coordinates.length > 0
      )
    }
  },

  methods: {
    showMap(idx) {
      if (this.mapReference == null) {
        this.mapReference = null
        this.mapReference = idx

        this.geocoder = new google.maps.Geocoder()

        const target = this.origin
        const { analysis: geoJSON = {} } = target

        if (Object.keys(geoJSON).length) {
          setTimeout(() => this.printMapPolygon(geoJSON.polygon, idx), 500)
        }
      } else {
        this.mapReference = null
      }
    },

    printMapPolygon(coordinates, idx) {
      function processPoints(geometry, callback, thisArg) {
        if (geometry instanceof google.maps.LatLng) {
          callback.call(thisArg, geometry)
        } else if (geometry instanceof google.maps.Data.Point) {
          callback.call(thisArg, geometry.get())
        } else {
          geometry.getArray().forEach((g) => {
            processPoints(g, callback, thisArg)
          })
        }
      }

      const map = new google.maps.Map(document.getElementById('map-' + idx), {
        zoom: 16,
        disableDefaultUI: true,
        mapTypeId: 'satellite',
        zoomControl: true
      })

      map.data.addGeoJson({
        type: 'Feature',
        property: 'polygon',
        geometry: coordinates
      })

      map.data.setStyle(() => ({
        strokeColor: '#F1BB00',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: '#F1BB00',
        fillOpacity: 0.32
      }))

      const bounds = new google.maps.LatLngBounds()
      map.data.forEach((feature) => {
        processPoints(feature.getGeometry(), bounds.extend, bounds)
      })
      map.fitBounds(bounds)
    },

    showAmbiental(data) {
      this.$store.state.home = false
      this.$store.state.history = false
      this.$store.state.ambiental = true
      this.$store.state.blockchain = false
      this.$store.state.prevNavigation = 'history'
      this.$store.state.headerTitle = this.texts.analise_ambiental_title
      this.$store.state.producer_data = data
    }
  }
}
</script>

<style lang="scss" scoped>
.selo-ecotrace {
  margin: 0 8px 0 0;
}

.container-infos {
  width: 100%;

  .infos {
    color: #2b2b2b;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 14px;
    width: 100%;

    p {
      margin-bottom: 8px;
      text-transform: capitalize;
    }

    span {
      display: block;
      text-transform: capitalize;
    }
  }

  .property_name {
    color: #f1bb00 !important;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 16px;
  }

  .btns-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 32px 0;

    > *:first-of-type {
      margin: 0 0 16px;
    }

    @media screen and (min-width: 410px) {
      flex-direction: row;
      flex-wrap: nowrap;

      > *:first-of-type {
        margin: 0 16px 0 0;
      }
    }
  }

  hr {
    border: 1px solid $tertiary-color;
  }

  .map-container {
    min-height: 200px;
    margin: 0 0 1rem 0;
  }
}

.container-infos:not(:first-of-type) {
  margin: 1rem 0 0 0;
}

.id-monitoramento {
  display: flex;
  margin: 1rem 0 0 0;

  .id {
    margin: 0 0 0 0.2rem;
    width: fit-content;
  }
}

.analysis {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
}

.laudo {
  margin: 0.5rem 0 0 0;
  text-transform: none !important;
  width: 100%;

  .url-laudo {
    font-family: 'Poppins', sans-serif;
    color: #744600;
    text-transform: none;
  }
}

.show-map {
  font-size: 9px;
  .v-btn__content {
    letter-spacing: normal;
  }
}
</style>
