<template>
  <header id="header">
    <nav class="top-navbar">
      <v-row no-gutters class="align-center">
        <v-col
          cols="8"
          class="d-flex align-center"
          :class="
            $store.state.menu_logo && $store.state.home
              ? 'flex-start'
              : 'justify-space-around'
          "
        >
          <v-btn
            text
            icon
            color="white"
            @click="goBack"
            v-if="!$store.state.home"
          >
            <v-icon size="16">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-img
            v-if="$store.state.menu_logo && $store.state.home"
            src="@/assets/images/logo-horizontal-branco.png"
            max-width="151"
            max-height="36"
            contain
            class="ml-6"
          />

          <h2 v-else class="title white--text">
            {{ $store.state.headerTitle }}
          </h2>
        </v-col>
        <v-col cols="4" class="d-flex justify-center align-center">
          <v-btn
            id="btn-language"
            dark
            class="help-button mr-2"
            color="#81C683"
            @click="goLanguage"
          >
            <v-img
              v-if="languageIcon === 'cn'"
              src="@/assets/images/bandeira-china.svg"
              max-width="40"
              max-height="40"
              contain
            />
            <v-img
              v-else-if="languageIcon === 'en'"
              src="@/assets/images/bandeira-inglaterra.svg"
              max-width="40"
              max-height="40"
              contain
            />
            <v-img
              v-else-if="languageIcon === 'es'"
              src="@/assets/images/bandeira-espanha.svg"
              max-width="40"
              max-height="40"
              contain
            />
            <v-img
              v-else
              src="@/assets/images/bandeira-brasil.svg"
              max-width="40"
              max-height="40"
              contain
            />
          </v-btn>
          <v-btn
            id="btn-ajuda"
            v-if="this.trackOrigem"
            dark
            class="help-button mr-2"
            color="#81C683"
            @click="goChat"
          >
            <v-img
              src="@/assets/icons/icone-pergunta.png"
              max-width="27"
              max-height="27"
              contain
            />
          </v-btn>
          <v-btn
            id="btn-blockchain"
            dark
            class="help-button"
            color="#81C683"
            @click="goBlockChain"
          >
            <v-img
              src="@/assets/icons/icone-blockchain.png"
              max-width="27"
              max-height="27"
              contain
            />
          </v-btn>
        </v-col>
      </v-row>
    </nav>
  </header>
</template>

<script>
import { goChat as $goChat } from '@/utils/chat'

export default {
  props: ['texts', 'trackOrigem'],
  data: () => ({
    languageIcon: 'pt-br'
  }),
  mounted() {
    this.languageIcon = this.$store.state.lang || this.texts.lang || 'pt-br'
  },
  watch: {
    texts() {
      this.languageIcon = this.texts.lang
    }
  },
  methods: {
    goBack() {
      if (
        this.$store.state.prevNavigation == 'home' ||
        !this.$store.state.producer_data
      ) {
        this.$store.state.home = true
        this.$store.state.history = false
        this.$store.state.ambiental = false
        this.$store.state.blockchain = false
        this.$store.state.certificados = false
        this.$store.state.languageSelector = false
        this.$store.state.chat = false
        this.$store.state.headerTitle = 'Dados do Produto'
        this.$store.state.headerTitle = this.texts.dados_produto
      } else {
        this.$store.state.home = false
        this.$store.state.history = true
        this.$store.state.ambiental = false
        this.$store.state.blockchain = false
        this.$store.state.certificados = false
        this.$store.state.languageSelector = false
        this.$store.state.chat = false
        this.$store.state.prevNavigation = 'home'
        this.$store.state.headerTitle = this.texts.historico_produto
      }
    },

    goBlockChain() {
      this.$store.state.blockchain = true
      this.$store.state.home = false
      this.$store.state.history = false
      this.$store.state.ambiental = false
      this.$store.state.seloEcotrace = false
      this.$store.state.languageSelector = false
      this.$store.state.chat = false
      this.$store.state.headerTitle = this.texts.blockchain
    },

    goLanguage() {
      this.$store.state.blockchain = false
      this.$store.state.home = false
      this.$store.state.history = false
      this.$store.state.ambiental = false
      this.$store.state.seloEcotrace = false
      this.$store.state.chat = false
      this.$store.state.languageSelector = true
      this.$store.state.headerTitle = this.texts.languageSelector
    },

    goChat() {
      return $goChat(this.trackOrigem, this.$route.query.lg)
    }
  }
}
</script>

<style lang="scss" scoped>
#header::v-deep {
  padding: 20px 12px;
  margin-bottom: 20px;

  .top-navbar {
    .col {
      &:nth-child(2) {
        @media screen and (max-width: 400px) {
          max-width: 50%;
        }
      }

      .help-button {
        display: block;
        min-width: inherit;
        height: 42px;
        width: 42px;
        max-width: 42px;
        max-height: 42px;
        padding: 0;
        border-radius: 50%;
        box-shadow: 0px 3px 29px #0000000d;
        span.v-btn__content {
          display: flex;
          flex-direction: column !important;

          .btn-text {
            margin-top: 3px;
            font-size: 11px;
            letter-spacing: 0;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
</style>
