<template>
  <div id="parent" class="text-field-outlined">
    <v-text-field
      :label="label"
      v-model="model"
      disabled
      outlined
      :class="primary ? 'primary-text' : ''"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    model: String,
    primary: Boolean
  }
}
</script>
<style scoped lang="scss">
#parent::v-deep {
  .primary-text {
    .v-input__slot {
      input {
        color: $primary-color !important;
      }
    }
  }
  .v-input__control > .v-input__slot fieldset {
    color: #f2f3f7 !important;
  }

  .theme--light.v-text-field--outlined fieldset {
    border-color: #f2f3f7;
  }

  .v-input__slot {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    padding-left: 16px;

    fieldset {
      legend {
        margin-left: 5px;
      }
    }

    label {
      line-height: 15px;
    }

    input {
      color: #315ca7;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .v-text-field__details {
    display: none;
  }
}

#parent.primary-text::v-deep {
  .v-input__slot {
    input {
      color: $primary-color !important;
    }
  }
}
</style>
