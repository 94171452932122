<template>
  <v-card id="HomeCard" class="Ecotrace-card">
    <v-card-text class="px-8">
      <v-row no-gutters>
        <v-col cols="8" class="d-flex justify-center align-center">
          <v-img
            src="@/assets/images/contra_file.png"
            max-width="198"
            max-height="224"
            contain
          ></v-img>
        </v-col>
        <v-col cols="4" class="d-flex flex-column justify-center align-center">
          <v-img
            src="@/assets/images/selosc.png"
            max-width="70"
            max-height="84"
            contain
            class="mb-2"
          ></v-img>

          <v-btn
            id="btn_panela"
            color="white"
            class="my-3 btn-product"
            fab
            x-large
            dark
          >
            <v-img
              contain
              max-width="34"
              max-height="31"
              src="@/assets/icons/panela.png"
            ></v-img>
          </v-btn>
          <v-btn
            id="btn_churrasco"
            color="white"
            class="my-3 btn-product"
            fab
            x-large
            dark
          >
            <v-img
              contain
              max-width="33"
              max-height="34"
              src="@/assets/icons/churrasco.png"
            ></v-img>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2 class="headline secondary--text font-weight-medium product-name">
            {{ getProductName(trackOrigem.product) | firstLetterEachWord }}
          </h2>
        </v-col>
      </v-row>
      <hr />
      <v-row class="infos-container">
        <v-col>
          <label>{{ texts.industria }}</label>
          <span class="secondary--text">{{ trackOrigem.industry.name }}</span>
        </v-col>
        <v-col v-if="trackOrigem.product.hash">
          <label>{{ texts.codigo_rastreio }}</label>
          <span class="primary--text">
            {{ trackOrigem.product.hash }}
          </span>
        </v-col>
      </v-row>
      <hr />
      <v-row class="infos-container">
        <v-col>
          <label>{{ texts.dt_producao }}</label>
          <span class="secondary--text">
            {{ track.production_date | formatDate }}
          </span>
        </v-col>
        <v-col v-if="trackOrigem.product.due_days">
          <label>{{ texts.dt_validade }}</label>
          <span class="primary--text">
            {{ getDueDate() | formatDate }}
          </span>
        </v-col>
      </v-row>
      <hr />
      <v-row
        v-if="trackOrigem.product.product_part"
        class="infos-container align-center"
      >
        <v-col>
          <label>{{ texts.tipo_corte }}</label>
          <span class="secondary--text">
            {{ trackOrigem.product.product_part_name | firstLetterEachWord }}
          </span>
        </v-col>
        <v-col>
          <v-img
            contain
            :src="
              require(`@/assets/icons/types/${trackOrigem.product.product_part}.png`)
            "
            max-width="173"
            max-height="123"
          ></v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            id="btn_historico_produto"
            width="100%"
            height="59"
            dark
            color="secondary"
            class="btn-historico-produto"
            @click="internPage"
          >
            {{ texts.historico_produto }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
const moment = require('moment')
export default {
  props: ['isHome', 'texts', 'track', 'trackDestino', 'trackOrigem'],

  created() {
    this.$store.state.headerTitle = this.texts.dados_produto
    if (this.$route.query.origem) {
      this.$store.state.home = false
      this.$store.state.history = true
      this.$store.state.origem = true
      //this.$store.state.prevNavigation = "home";
      this.$store.state.headerTitle = this.texts.info_origem
      return
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  },

  methods: {
    getDueDate() {
      return moment(this.track.production_date)
        .add('days', this.trackOrigem.product.due_days)
        .format('YYYY-MM-DD')
    },
    internPage() {
      this.$store.state.home = false
      this.$store.state.history = true
      this.$store.state.prevNavigation = 'home'
      this.$store.state.headerTitle = this.texts.historico_produto
    },

    seloEcotracePage() {
      this.$store.state.home = false
      this.$store.state.seloEcotrace = true
      this.$store.state.prevNavigation = 'home'
      this.$store.state.headerTitle = this.texts.selo_ecotrace
    },

    getProductName(product) {
      if (product.languages) {
        for (let i = 0; i < product.languages.length; i++) {
          let lg = product.languages[i]
          if (lg.language == this.texts.globalLang) {
            return lg.name
          }
        }
      }

      return product.name
    }
  }
}
</script>

<style lang="scss" scoped>
.Ecotrace-card {
  padding-bottom: 20px;

  #selo-ecotrace {
    width: 110px;
    height: 95px;
    box-shadow: none;
  }

  .btn-product {
    max-width: 55px;
    max-height: 55px;
    box-shadow: 0px 3px 15px #0000001a;
  }

  hr {
    border: 1px solid $tertiary-color;
  }

  .btn-historico-produto {
    font-size: 16px;
    border-radius: 12px;
    box-shadow: 0px 17px 31px #00000024;
  }

  .label-text {
    font-size: 18px;
    line-height: 22px;
    color: #6a707e;
  }

  .data-text {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: $secondary-color;
    margin: 0;
  }

  .separator {
    border: 0;
    border-bottom: 1px solid #f2f3f7;
  }

  .brand-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 20px 0;
    background-color: #f4f8f7;
    overflow: hidden;
    box-shadow: 0px 3px 0px #0000000a;
  }
}

h2.product-name {
  margin: 0 0 0.5rem 0 !important;
}

.infos-container {
  padding: 1rem 0;

  label {
    display: block;
    font-size: 18px;
  }
  span {
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
  }
}

.btn-historico-produto {
  margin: 1rem 0 0 0;
}
</style>
