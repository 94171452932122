export default {
    frigorifico : 'Frigorífico',
    dt_producao : 'Data de Produção',
    cnpj : 'CNPJ',
    email : 'E-mail',
    telefone : 'Telefone',
    fazenda : 'Fazenda',
    cidade : 'Cidade',
    origem : 'Origem'
}
