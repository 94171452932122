<template>
  <v-card id="ChatCard" class="Ecotrace-card">
    <v-btn
      absolute
      right
      fab
      small
      depressed
      color="white"
      class="close-btn secondary--text"
      @click="close"
    >
      <v-icon>fas fa-times</v-icon>
    </v-btn>
    <v-card-text class="px-4 pt-12">
      <v-col class="text-left mb-n2">
        <p class="primary--text font-weight-medium subtitle-1 mb-0">
          {{ texts.deixe_mensagem }}
        </p>
      </v-col>
      <v-row>
        <v-col class="text-left">
          <p class="px-3">
            {{ texts.mensagem_header_text }}
          </p>
          <TextField v-if="trackOrigem.product" active :model="trackOrigem.product.name" class="mb-5" disabled />
          <TextField
            v-if="trackOrigem.product"
            active
            :model="trackOrigem.product.sku"
            class="mb-5"
            disabled
          />
          <v-text-field
            v-model="fields.name"
            placeholder="Seu nome"
            solo
            class="eco-textfield mb-5"
            @input="validateFields"
          ></v-text-field>
          <v-text-field
            v-model="fields.mail"
            placeholder="Seu email"
            type="email"
            solo
            class="eco-textfield mb-5"
            @input="validateFields"
          ></v-text-field>
          <v-textarea
            v-model="fields.content"
            placeholder="Sua dúvida"
            solo
            class="eco-textfield"
            @input="validateFields"
          />

          <v-row>
            <v-col>
              <v-btn
                width="100%"
                height="59"
                dark
                color="secondary"
                class="mt-3 btn-feedback"
                @click="sendChatMessage"
                :loading="buttonLoading"
                :disabled="!validation"
              >
                {{ texts.enviar_minha_duvida }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-scroll-y-reverse-transition>
      <v-snackbar
        v-model="snackbar.status"
        :color="snackbar.type ? 'primary' : 'red'"
      >
        <span class="ma-auto subtitle-1">{{ snackbar.text }}</span>
      </v-snackbar>
    </v-scroll-y-reverse-transition>
  </v-card>
</template>

<script>
import TextField from "@/components/TextField";
import appApi from "@/plugins/api";

export default {
  props: ["texts", "track", "trackOrigem"],
  components: {
    TextField
  },

  data() {
    return {
      buttonLoading: false,
      snackbar: {
        status: false,
        type: true,
        text: ""
      },
      fields: {
        name: null,
        mail: null,
        content: null
      },
      validation: false
    };
  },

  methods: {
    sendChatMessage() {
      this.buttonLoading = true;
      appApi
        .postChatMessage(this.$route.query.sif, {
          product_name: this.track.product ? this.track.product.name : '',
          track_id: this.track ? this.track.codigo_rastreio : '',
          name: this.fields.name,
          email: this.fields.mail,
          content: this.fields.content
        })
        .then(result => {
          this.buttonLoading = false;
          (this.fields.name = null),
            (this.fields.mail = null),
            (this.fields.content = null),
            (this.snackbar.status = true);
          this.validation = false;
          this.snackbar.type = true;
          this.snackbar.text = "Mensagem enviada com sucesso.";

          setTimeout(() => {
            this.close();
          }, 5000);
        })
        .catch(err => {
          this.buttonLoading = false;
          this.snackbar.status = true;
          this.snackbar.type = false;
          this.snackbar.text = "Erro ao tentar enviar a mensagem.";
        });
    },
    validateFields() {
      if (
        this.fields.name &&
        this.fields.name.length > 0 &&
        this.fields.mail &&
        this.fields.mail.length > 0 &&
        this.fields.content &&
        this.fields.content.length > 0
      ) {
        this.validation = true;
      } else {
        this.validation = false;
      }
    },
    close() {
      this.$store.state.chat = false;
      this.$store.state.home = true;
      this.$store.state.headerTitle = "Dados do Produto";
    }
  }
};
</script>

<style lang="scss" scoped>
#ChatCard::v-deep {
  .eco-textfield {
    .v-input__control .v-input__slot {
      -webkit-box-shadow: none;
      box-shadow: none;
      margin: 0;
      padding-left: 16px;
      border: 1px solid #e9e5e5;

      input,
      textarea {
        font-size: 16px;
        font-weight: 300;
      }
    }

    .v-text-field__details {
      display: none;
    }
  }
  .close-btn {
    top: 22px;
    right: 22px;
  }

  .btn-feedback.v-btn--disabled {
    background-color: $secondary-color !important;
    opacity: 0.3;
  }
}
</style>
