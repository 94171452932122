<template>
  <v-timeline
    id="barcellos-timeline"
    class="barcellos-timeline"
    align-top
    dense
  >
    <v-timeline-item class="info-origem" fill-dot color="white">
      <template v-slot:icon>
        <v-img contain src="@/assets/barcellos/item_origem.svg" />
      </template>
      <h2 class="title text-uppercase primary--text mt-1">
        {{ texts.info_origem }}
      </h2>
      <ExpandableBiomes
        v-if="shouldDisplayBiome('AMAZÔNIA')"
        :biomaName="'Amazônia'"
        :biomaMapImage="require('@/assets/barcellos/amazonia.png')"
        :bgColor="'#4C87001A'"
        :textColor="'#4C8700'"
        :track="filterArrayBiomaAndReturnObjectUpdated('AMAZÔNIA')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('CERRADO')"
        :biomaName="'Cerrado'"
        :biomaMapImage="require('@/assets/barcellos/cerrado.png')"
        :bgColor="'#8749001A'"
        :textColor="'#874900'"
        :track="filterArrayBiomaAndReturnObjectUpdated('CERRADO')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('CAATINGA')"
        :biomaName="'Caatinga'"
        :biomaMapImage="require('@/assets/barcellos/caatinga.png')"
        :bgColor="'#F1BB001A'"
        :textColor="'#F1BB00'"
        :track="filterArrayBiomaAndReturnObjectUpdated('CAATINGA')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('MATA ATLÂNTICA')"
        :biomaName="'Mata Atlântica'"
        :biomaMapImage="require('@/assets/barcellos/mata-atlantica.png')"
        :bgColor="'#3055001A'"
        :textColor="'#305500'"
        :track="filterArrayBiomaAndReturnObjectUpdated('MATA ATLÂNTICA')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('PANTANAL')"
        :biomaName="'Pantanal'"
        :biomaMapImage="require('@/assets/barcellos/pantanal.png')"
        :bgColor="'#00875F1A'"
        :textColor="'#00875E'"
        :track="filterArrayBiomaAndReturnObjectUpdated('PANTANAL')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('PAMPA')"
        :biomaName="'Pampa'"
        :biomaMapImage="require('@/assets/barcellos/pampa.png')"
        :bgColor="'#0067871A'"
        :textColor="'#006787'"
        :track="filterArrayBiomaAndReturnObjectUpdated('PAMPA')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />

      <ExpandableBiomes
        v-if="shouldDisplayBiome('SEM BIOMA')"
        :biomaName="'Bioma não informado'"
        :biomaMapImage="require('@/assets/barcellos/error-bioma.png')"
        :bgColor="'#7070701A'"
        :textColor="'#707070'"
        :track="filterArrayBiomaAndReturnObjectUpdated('SEM BIOMA')"
        :texts="texts"
        :idx="idx"
        :key="idx"
      />
    </v-timeline-item>

    <v-timeline-item id="info-industria" fill-dot color="white" right>
      <template v-slot:icon>
        <v-img contain src="@/assets/barcellos/item_industria.svg" />
      </template>
      <h2 class="title text-uppercase primary--text mt-1">
        {{ texts.info_industria }}
      </h2>
      <industry
        :texts="texts"
        :track="track"
        :trackDestino="trackDestino"
        :trackOrigem="trackOrigem"
      />
    </v-timeline-item>

    <v-timeline-item id="info-varejo" fill-dot color="white">
      <template v-slot:icon>
        <v-img contain src="@/assets/barcellos/item_assistente_virtual.svg" />
      </template>
      <h2 class="title text-uppercase primary--text mt-1">
        {{ texts.info_varejo }}
      </h2>
      <retail :texts="texts" :track="track" :trackOrigem="trackOrigem" />
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import Industry from '@/components/Barcellos/InfoIndustria'
import ExpandableBiomes from '@/components/Barcellos/ExpandableBiomes'
import Retail from '@/components/Barcellos/InfoVarejo'

export default {
  props: ['texts', 'track', 'trackOrigem', 'trackDestino'],
  components: {
    ExpandableBiomes,
    Industry,
    Retail
  },

  computed: {
    shouldDisplayBiome() {
      return (biomeName) => {
        return (
          this.filterArrayBiomaAndReturnObjectUpdated(biomeName).environmental
            .length > 0
        )
      }
    },

    filterArrayBiomaAndReturnObjectUpdated() {
      return (biomaName) => {
        const biomaPadrao = '(SEM BIOMA|PROPRIEDADE SEM BIOMA)'

        const arrayFiltrado = this.trackOrigem.environmental
          .map((item) => ({
            ...item,
            bioma: item.bioma || biomaPadrao
          }))
          .filter((item) => this.extractBioma(item.bioma) === biomaName)

        return {
          ...this.trackOrigem,
          environmental: arrayFiltrado
        }
      }
    }
  },

  methods: {
    extractBioma(biomaName) {
      const matches = biomaName.match(/\(([^|]+)\|/)
      return matches ? matches[1] : null
    }
  }
}
</script>

<style lang="scss" scoped>
:root {
  $timeline-divider-width: 1px;
  $timeline-item-padding: 8px;
}

.barcellos-timeline::v-deep {
  padding: 0;

  &::before {
    background-color: #2b2b2b;
    height: calc(100% - 80px);
    width: 3px;
  }

  .title {
    color: #2b2b2b !important;
    font-family: 'Source Sans Pro' sans-serif;
    font-weight: 900;
    margin: 0 0 16px 0;
  }

  .v-timeline-item__body {
    padding: 0 0 0 16px;
  }
}
</style>
