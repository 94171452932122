<template>
  <svg  fill="#ffffff" enable-background="new 0 0 30 30" height="30" viewBox="0 0 512 512" width="30" xmlns="http://www.w3.org/2000/svg"><path d="m304.703125 400.601562h-160.667969c-17.71875 0-32.132812 14.414063-32.132812 32.132813v79.265625h224.933594v-79.265625c0-17.71875-14.414063-32.132813-32.132813-32.132813zm0 0"/><path d="m170.710938 309.660156c-2.5625-2.558594-5.949219-4.089844-9.539063-4.351562v65.292968h126.398437v-65.292968c-3.589843.261718-6.980468 1.792968-9.550781 4.363281-14.328125 14.328125-33.378906 22.21875-53.648437 22.21875-20.269532 0-39.320313-7.890625-53.660156-22.230469zm0 0"/><path d="m317.570312 308.199219v63.75c28.105469 5.941406 49.265626 30.933593 49.265626 60.785156v79.265625h50.335937c8.277344 0 15-6.71875 15-15v-48.199219c0-69.230469-49.28125-127.179687-114.601563-140.601562zm0 0"/><path d="m131.171875 308.199219c-65.320313 13.421875-114.601563 71.371093-114.601563 140.601562v48.199219c0 8.28125 6.71875 15 15 15h50.332032v-79.265625c0-29.851563 21.160156-54.84375 49.269531-60.785156zm0 0"/><path d="m433.261719 77.136719h-81.480469c-1.132812-42.726563-36.21875-77.136719-79.210938-77.136719h-96.398437c-42.996094 0-78.078125 34.410156-79.214844 77.136719h-81.457031c-8.28125 0-15 6.730469-15 15.011719 0 27.199218 22.050781 49.25 49.25 49.25h349.261719c27.199219 0 49.25-22.050782 49.25-49.25 0-8.28125-6.722657-15.011719-15-15.011719zm0 0"/><path d="m335.769531 175.667969v-4.269531h-222.800781v4.269531c0 61.621093 49.878906 111.398437 111.402344 111.398437 61.621094 0 111.398437-49.875 111.398437-111.398437zm0 0"/></svg>                       
</template>

<script>
export default {

}
</script>

<style>

</style>