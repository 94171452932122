<template>
  <v-card id="AmbientalCard" class="barcellos-card">
    <v-card-text class="px-6 py-12">
      <v-row class="mb-4">
        <v-col>
          <div
            class="shadow-container ambiental-title"
            v-if="producerData.property_current_status === false"
          >
            <h2 class="headline --center">
              {{ texts.propriedade_nao_conforme }}
            </h2>
          </div>

          <div
            class="shadow-container ambiental-title"
            v-else-if="producerData.property_current_status === true"
          >
            <v-img
              contain
              class="seal"
              max-height="72"
              max-width="56"
              src="@/assets/icons/selo_ambiental.png"
            />
            <h2 class="headline">
              {{ texts.propriedade_conforme }}
            </h2>
          </div>

          <div v-else class="shadow-container ambiental-title">
            <h2 class="headline --center">
              {{ texts.propriedade_com_inconsistencia }}
            </h2>
          </div>
        </v-col>
      </v-row>

      <v-row
        class="item justify-space-between align-stretch"
        v-for="(item, idx) in dataAmbiental"
        :key="idx"
      >
        <v-col cols="8" class="d-flex align-center">
          <span class="item-label subtitle-1 font-weight-medium">
            {{ item.name | zhCn({ texts }) }}
          </span>
        </v-col>
        <v-col cols="4" class="item-value d-flex align-center justify-center">
          <span
            v-if="producerData[item.api_key] === true"
            class="subtitle-1 font-weight-medium primary--text"
          >
            <v-icon color="#3FAB34" class="mr-4">fas fa-check-circle</v-icon>
          </span>
          <span
            v-else-if="producerData[item.api_key] === false"
            class="subtitle-1 font-weight-medium primary--text"
          >
            <v-icon color="#e74c3c" class="mr-4">fas fa-times-circle</v-icon>
          </span>
          <span v-else class="subtitle-1 font-weight-medium primary--text">
            <v-icon color="#e67e22" class="mr-4">fas fa-question-circle</v-icon>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['texts', 'track', 'show', 'trackOrigem'],

  data() {
    return {
      dataAmbiental: null,
      producerData: null
    }
  },

  filters: {
    zhCn(val, { texts }) {
      if (val in texts) return texts[val]
      return val
    }
  },

  created() {
    this.dataAmbiental = this.track.ambiental
    this.producerData = this.$store.state.producer_data.analysis
    this.$store.state.origem = false
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
#AmbientalCard::v-deep {
  background-color: #f7f7f9;
  box-shadow: none;
  border-radius: 0;
  height: 100%;

  .subtitle-1.primary--text {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .shadow-container.ambiental-title {
    background: url('../../assets/barcellos/button-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 16px 32px;

    .seal {
      filter: grayscale(1) brightness(0) invert(1);
      margin: 0 8px 0 0;
    }

    h2 {
      font-family: 'Source Sans Pro', sans-serif !important;
      font-size: 24px !important;
      font-weight: 900;
      color: white;
      line-height: 32px;
      display: inline-block;
      text-align: center;
      max-width: 176px;

      &.--center {
        max-width: 100%;
      }
    }
  }

  .shadow-container {
    box-shadow: 0px 3px 22px #00000017;
    border-radius: 16px;
  }

  .item {
    border-bottom: 2px solid #e8e8e8;
    margin: 0;
    padding: 32px 0;

    .col-4,
    .col-8 {
      padding: 0 !important;
    }

    .item-label,
    .item-value > span {
      font-family: 'Source Sans Pro', sans-serif !important;
      font-weight: 900 !important;
    }
  }
}
</style>
