<template>
  <div
    class="card v-card-space"
    :style="{ backgroundColor: bgColor, color: textColor }"
  >
    <div class="card-body">
      <div class="row px-5 align-center" @click="toggleContent">
        <!-- Lado Esquerdo: Nome do Bioma -->
        <div class="col-md-8">
          <h2 class="card-title">{{ biomaName }}</h2>
        </div>

        <!-- Lado Direito: Imagem do Mapa -->
        <div class="col-md-4">
          <v-img
            contain
            max-width="100"
            max-height="100"
            :src="biomaMapImage"
          ></v-img>
        </div>
      </div>

      <!-- Ícone de seta para baixo para expandir -->
      <div class="text-center" @click="toggleContent">
        <i
          class="fa fa-chevron-down"
          aria-hidden="true"
          :class="{ 'rotate-icon': showContent }"
        ></i>
      </div>

      <!-- Conteúdo Expansível -->
      <div v-if="showContent" class="mt-3 v-content-card">
        <!-- Seu conteúdo aqui -->

        <template v-for="(origem, idx) in paginatedEnvironmentals">
          <Origins
            :origin="origem"
            :track="track"
            :texts="texts"
            :idx="idx"
            :key="idx"
            @click.native="handleOriginsClick"
          />
        </template>

        <Pagination
          v-if="totalItems > perPage"
          @next-page="setCurrentPage"
          :currentPage="currentPage"
          :lastPage="lastPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Origins from '@/components/blocks/ProductorOrigin'
import Pagination from '@/components/Pagination'

export default {
  props: [
    'biomaName',
    'biomaMapImage',
    'bgColor',
    'textColor',
    'texts',
    'idx',
    'track'
  ],

  components: {
    Origins,
    Pagination
  },

  data() {
    return {
      showContent: false,
      currentPage: 1,
      lastPage: 1,
      perPage: 3,
      totalItems: 0,
      paginatedEnvironmentals: []
    }
  },

  mounted() {
    Object.assign(this, {
      totalItems: (this.track && this.track.environmental.length) || 0
    })

    if (this.totalItems > 0) {
      this.paginateItems()
      this.lastPage = Math.ceil(this.totalItems / this.perPage)
    }
  },

  methods: {
    paginateItems() {
      this.paginatedEnvironmentals = this.track.environmental.filter(
        (_, idx) =>
          idx < this.currentPage * this.perPage &&
          idx >= (this.currentPage - 1) * this.perPage
      )
    },

    setCurrentPage(acc) {
      this.currentPage += acc
      this.paginateItems()
    },

    toggleContent() {
      this.showContent = !this.showContent
    },

    handleOriginsClick(event) {
      event.stopPropagation()
    }
  }
}
</script>

<style scoped>
.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.v-card-space {
  margin-top: 30px;
}

.v-content-card {
  background-color: white;
}
</style>
