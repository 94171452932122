<template>
  <a
    :disabled="disabled"
    class="cta parallelogram-button"
    id="fale-com-barcellos"
    :class="[`--${variant}`, `--${size}`, disabled ? '--disabled' : '']"
    @click="onClickCheck"
  >
    <span :disabled="disabled" class="_container">
      <slot name="icon" />
      <p :disabled="disabled" class="label">{{ label }}</p>
    </span>
  </a>
</template>

<script>
export default {
  name: 'ParallelogramButton',
  props: {
    variant: { type: String, default: 'primary' },
    size: { type: String, default: '' },
    label: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    onClick: { type: Function, required: true }
  },

  computed: {
    onClickCheck() {
      if (!this.disabled) {
        return this.onClick
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.parallelogram-button {
  align-items: center;
  display: flex;
  height: 40px;
  padding: 8px;
  justify-content: center;
  text-decoration: none;
  position: relative;
  width: fit-content;
  white-space: nowrap;

  > ._container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 16px;
    z-index: 9;

    .label {
      color: white;
      font-family: 'Poppins' sans-serif;
      font-weight: 500;
      font-size: 10px;
      text-transform: uppercase;
      margin: 0 0 0 8px;
    }
  }

  &::before {
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    content: '';
    height: 100%;
    position: absolute;
    transform: skew(-24deg);
    transition: filter 400ms;
    width: 100%;
    will-change: filter;
  }

  &.--primary {
    &::before {
      background-color: #744600;
    }
  }

  &.--secondary {
    &::before {
      background-color: #f1bb00;
    }
  }

  &.--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.--lg {
    &::after {
      transform: skew(-48deg);
    }

    .label {
      font-family: 'Source Sans Pro' sans-serif;
      font-size: 14px;
      text-transform: none;
    }
  }

  &:hover {
    &::before {
      filter: brightness(0.64);
    }
  }
}
</style>
