<template>
  <div id="TemplatePage">
    <TopAppBar
      :trackOrigem="trackOrigem"
      :isHome="isHome"
      :texts="texts"
      :pageTitle="menuTextValue"
    />
    <v-scroll-y-reverse-transition>
      <HomeCard
        :isHome="isHome"
        :texts="texts"
        :track="track"
        :trackOrigem="trackDestino"
        transition="scroll-y-reverse-transition"
        v-if="$store.state.home"
      />
    </v-scroll-y-reverse-transition>
    <v-scroll-y-reverse-transition>
      <InternCard
        :isHome="isHome"
        :texts="texts"
        :track="track"
        :trackDestino="trackDestino"
        :trackOrigem="trackOrigem"
        v-if="$store.state.history"
      />
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <AmbientalCard
        :isAmbiental="isAmbiental"
        :texts="texts"
        :track="track"
        v-if="$store.state.ambiental"
      />
    </v-scroll-y-reverse-transition>
    <v-scroll-y-reverse-transition>
      <BlockChainCard :texts="texts" v-if="$store.state.blockchain" />
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <SeloEcotraceCard :texts="texts" v-if="$store.state.seloEcotrace" />
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <ChatCard
        v-if="$store.state.chat"
        :track="track"
        :trackOrigem="trackOrigem"
        :texts="texts"
      />
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <CertificadosCard :texts="texts" v-if="$store.state.certificados" />
    </v-scroll-y-reverse-transition>
  </div>
</template>

<script>
import mockDataFriboi from '../../assets/dataMockFriboi'

// Components //
import TopAppBar from '@/components/friboi-cards/TopAppBar'
import HomeCard from '@/components/friboi-cards/HomeCard'
import InternCard from '@/components/friboi-cards/InternCard'
import AmbientalCard from '@/components/friboi-cards/AnaliseAmbientalCard'
import BlockChainCard from '@/components/cards/BlockChainCard'
import SeloEcotraceCard from '@/components/cards/SeloEcotraceCard'
import ChatCard from '@/components/friboi-cards/ChatCard'
import CertificadosCard from '@/components/cards/CertificadosCard'

// Languages //
import langPTBR from './pt-br.ts'
import langCN from './cn.ts'

export default {
  components: {
    TopAppBar,
    HomeCard,
    InternCard,
    AmbientalCard,
    BlockChainCard,
    SeloEcotraceCard,
    ChatCard,
    CertificadosCard
  },

  data() {
    return {
      isHome: true,
      isHistory: false,
      isAmbiental: false,
      firstAccess: true,
      menuText: 'Histórico do Produto',
      data: {},
      track: {},
      sif: null,
      trackDestino: mockDataFriboi,
      trackOrigem: mockDataFriboi,
      texts: {}
    }
  },

  computed: {
    changeDataValue() {
      return this.data
    },

    menuTextValue() {
      return this.menuText
    }
  },

  created() {
    this.track = mockDataFriboi
    this.sif = '0042'

    if (this.track && this.trackDestino) {
      if (this.$route.query.lg) {
        switch (this.$route.query.lg) {
          case 'pt-br':
            this.texts = langPTBR
            break
          case 'pt':
            this.texts = langPTBR
            break
          case 'cn':
            this.texts = langCN
            break
          case 'zh-cn':
            this.texts = langCN
            break
          case 'zhHans':
            this.texts = langCN
            break
          default:
            this.texts = langPTBR
            break
        }
      } else {
        this.texts = langPTBR
      }
    }
  },

  mounted() {}
}
</script>

<style lang=""></style>
