export default {
  inicio: 'Inicio',
  origem: 'Origen',
  industria: 'Industria',
  dt_producao: 'Fecha de producción',
  dt_validade: 'Fecha de vencimiento',
  dt_recebimento: 'Fecha de recepción',
  codigo_rastreio: 'Código de rastreo',
  tipo_corte: 'Tipo de corte',
  lote_producao: 'Lote de producción',
  nome: 'Nombre',
  cnpj: 'CNPJ',
  email: 'Correo electrónico',
  telefone: 'Teléfono',
  fazenda: 'Hacienda',
  cidade: 'Ciudad',
  historico_produto: 'Historial del producto',
  dados_produto: 'Datos del producto',
  info_origem: 'Información de origen - Biomas',
  ver_mapa: 'Ver en el mapa',
  analise_ambiental: 'Análisis ambiental',
  certificados: 'Certificados',
  blockchain: 'Blockchain',
  contato: 'Contacto',
  selo_ecotrace: 'Sello Ecotrace',
  info_industria: 'Información de la industria',
  dados_unidade_produtora: 'Datos de la unidad productora',
  localizacao_unidade_produtora: 'Ubicación de la unidad productora',
  certificados_unidade_produtora: 'Certificados de la unidad productora',
  planta_habilitada_exportacao: 'Planta habilitada para la exportación',
  ver_lista_completa: 'Ver lista completa',
  ver_lista_reduzida: 'Ver lista reducida',
  info_varejo: 'Asistente Virtual',
  botao_chatbot: '¿Le gustaría hablar con nuestro asistente virtual?',
  localizacao_varejo: 'Ubicación de la tienda',
  ajuda_sobre_produto: '¿Necesita ayuda con este producto?',
  fale_com_barcellos: 'Contáctese con Barcellos',
  fazer_pergunta: 'Hacer pregunta',
  propriedade_conforme: '¡Propiedad 100% APTA!',
  nada_consta: 'Nada Consta',
  certificados_title: 'SIF - REGISTRO DE ESTABLECIMIENTOS',
  certificados_text:
    'El registro de establecimientos ante el Servicio de Inspección Federal (SIF) es obligatorio para todas las industrias de productos de origen animal: carne y derivados, leche y derivados, pescados, miel y huevos; que deseen comercializar sus productos fuera de las fronteras estatales o federales.',
  blockchain_title: 'Datos protegidos por blockchain',
  blockchain_text:
    'Blockchain es una tecnología de registro distribuido que busca la descentralización como medida de seguridad. Son bases de registros y datos distribuidos y compartidos que tienen la función de crear un índice global para todas las transacciones que ocurren en un mercado determinado.',
  seloecotrace_title: 'Datos protegidos por blockchain',
  seloecotrace_text:
    'La blockchain es una tecnología de registro distribuido que busca la descentralización como medida de seguridad. Son bases de registros y datos distribuidos y compartidos que tienen la función de crear un índice global para todas las transacciones que ocurren en un mercado determinado.',
  deixe_mensagem: 'Deje su mensaje',
  mensagem_header_text:
    'Si encuentra algún problema con este producto o tiene alguna pregunta, por favor háganoslo saber. Responderemos lo antes posible.',
  enviar_minha_duvida: 'Enviar mi duda',
  produto_rastreabilidade: 'Producto con rastreabilidad de producción',
  consulta_dados_historico_ecotrace:
    'Ahora puede consultar todos los datos del historial de producción de este producto con Tecnología',
  dados_disponiveis_breve: 'Los datos estarán disponibles en breve',
  rastreabilidade_da_producao: 'Rastreabilidad de la producción',
  dados_disponiveis_em:
    'Los datos de este producto están en procesamiento y estarán disponibles en',
  produto_frigol: 'Producto frigol',

  propriedade_nao_conforme: '¡Propiedad no APTA!',
  analise_status_conforme: 'Conforme',
  analise_status_nao_conforme: 'No Conforme',
  propriedade_com_inconsistencia: '¡Propiedad con Inconsistencia!',

  id_monitoramento: 'ID Monitoreo',
  analise_ambiental_title: 'Análisis Ambiental',
  empresa_responsavel_monitoramento: 'Empresa responsable del monitoreo',

  Desmatamento: 'Deforestación',
  'Trabalho escravo': 'Trabajo esclavo',
  'Invasão de Terras Indígenas': 'Invasión de Tierras Indígenas',
  'Embargo IBAMA': 'Embargo de IBAMA',
  'Invasão de Unidade de Conservação Ambiental':
    'Invasión de Unidad de Conservación Ambiental',

  nao_encontrado_title: 'Necesitamos algunas informaciones',
  informacao_nao_econtrada: 'Información no encontrada',
  ajuda:
    'Si necesita ayuda, por favor contáctenos a través del correo electrónico',
  como_acessar:
    'Para acceder a toda la información del producto, proporcione los datos que aparecen en el embalaje.',
  ver_dados_do_produto: 'Ver datos del Producto',
  languageSelector: 'Seleccione el idioma',
  lingua_pt_br: 'Portugués',
  lingua_en: 'Inglés',
  lingua_es: 'Español',
  lingua_cn: 'Chino'
}
