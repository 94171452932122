<template>
  <div id="Footer" class="px-4 pl-12 py-8">
    <v-row>
      <v-col class="py-0">
        <v-img
          src="@/assets/images/logo-horizontal.png"
          max-width="138"
        ></v-img>
      </v-col>
    </v-row>
    <v-row class="mb-10">
      <v-col>
        <p class="quaternary--text">{{ texts.ajuda_sobre_produto }}</p>
      </v-col>
      <v-col>
        <v-btn
          id="btn_fazer_pergunta"
          @click="goChat"
          color="secondary"
          rounded
        >
          <v-img
            src="@/assets/icons/icone-pergunta.png"
            max-width="27"
            max-height="27"
            contain
            class="mr-2"
          ></v-img>
          <span class="btn-text subtitle-2 text-capitalize">
            {{ texts.fazer_pergunta }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    goChat() {
      this.$store.state.blockchain = false
      this.$store.state.home = false
      this.$store.state.history = false
      this.$store.state.ambiental = false
      this.$store.state.seloEcotrace = false
      this.$store.state.chat = true
      this.$store.state.headerTitle = this.texts.contato
    }
  },
  props: ['texts']
}
</script>

<style lang="scss" scoped>
#Footer::v-deep {
  background-color: #f4f8f7;

  p {
    font-size: 18px;
    line-height: 18px;
  }

  button {
    box-shadow: 0px 3px 15px #0000001a;
  }
}
</style>
