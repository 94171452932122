<template>
  <v-card id="InternCard" class="Ecotrace-card">
    <v-card-text class="px-4">
      <EcoTimeLine
        :texts="texts"
        :track="track"
        :trackDestino="trackDestino"
        :trackOrigem="trackOrigem"
      />
    </v-card-text>
    <QRFooter :texts="texts" />
    <BottomNavigation :texts="texts" />
  </v-card>
</template>

<script>
import EcoTimeLine from "@/components/TimeLine";
import QRFooter from "@/components/Footer";
import BottomNavigation from "@/components/BottomNavigation";
export default {
  props: ["texts", "track", "trackDestino", "trackOrigem"],
  components: {
    EcoTimeLine,
    QRFooter,
    BottomNavigation
  }
};
</script>

<style lang="scss" scoped></style>
