<template>
  <div>
    <div v-if="!showSearchPage">
      <v-card id="BarcellosCard" class="barcellos-card">
        <v-card-text class="px-6" v-if="!isProgrammatic">
          <v-row class="product-title" no-gutters>
            <h1>
              {{ trackOrigem.product.name }}
            </h1>
          </v-row>
          <v-row class="product" no-gutters>
            <div class="wrapper">
              <img
                cover
                class="display"
                :alt="trackOrigem.product.name"
                :title="trackOrigem.product.name"
                :src="trackOrigem.product.image_url"
                v-if="trackOrigem.product.image_url"
              />
              <v-img
                alt="BBQ Secrets by Barcellos"
                class="display fallback"
                src="@/assets/barcellos/fallback-product.png"
                title="BBQ Secrets by Barcellos"
                cover
                v-else
              />
            </div>
          </v-row>
          <v-row class="contact">
            <div class="brand-wrapper">
              <picture class="brand">
                <source
                  srcset="../../assets/barcellos/bbq-secrets-brand-vertical.jpg"
                  media="(min-width: 375px)"
                />
                <img
                  src="../../assets/barcellos/bbq-secrets-brand-horizontal.jpg"
                  alt="BBQ Secrets"
                />
              </picture>
            </div>
            <div class="cta-wrapper">
              <h3 class="title">{{ texts.ajuda_sobre_produto }}</h3>
              <parallelogram-button
                :label="texts.fale_com_barcellos"
                size="lg"
                :onClick="goChat"
              >
                <v-img
                  contain
                  src="@/assets/icons/icone-pergunta.png"
                  max-width="24"
                  max-height="24"
                  slot="icon"
                />
              </parallelogram-button>
            </div>
          </v-row>
          <v-row class="industry prop-display-wrapper">
            <v-col class="prop-display">
              <label>{{ texts.industria }}</label>
              <span class="text --primary">
                {{ trackOrigem.industry.name }}
              </span>
            </v-col>
            <v-col>
              <v-img src="@/assets/barcellos/frigol-brand.svg" contain />
            </v-col>
          </v-row>
          <v-row class="track-code prop-display-wrapper">
            <v-col class="prop-display">
              <label>{{ texts.codigo_rastreio }}</label>
              <span class="text --secondary">
                {{ trackOrigem.product.hash }}
              </span>
            </v-col>
          </v-row>
          <hr />

          <v-row class="dates prop-display-wrapper">
            <v-col class="prop-display">
              <label>{{ texts.dt_producao }}</label>
              <span class="text --secondary --brown">
                {{ track.production_date | formatDate }}
              </span>
            </v-col>
            <v-col
              class="prop-display"
              v-if="trackOrigem.product && trackOrigem.product.due_days"
            >
              <label>{{ texts.dt_validade }}</label>
              <span class="text --secondary">
                {{ getDueDate() | formatDate }}
              </span>
            </v-col>
          </v-row>
          <v-row
            v-if="haveProduct && trackOrigem.product.product_part"
            class="part prop-display-wrapper"
          >
            <v-col class="prop-display">
              <label>{{ texts.tipo_corte }}</label>
              <span class="product-name text --secondary --bold">
                {{
                  trackOrigem.product.product_part_name | firstLetterEachWord
                }}
              </span>
            </v-col>
            <v-col>
              <v-img
                contain
                :src="
                  require(
                    `@/assets/icons/types/${trackOrigem.product.product_part}.png`
                  )
                "
                max-width="176"
                max-height="120"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                class="btn-historico-produto"
                color="secondary"
                height="64"
                id="btn_historico_produto"
                width="100%"
                dark
                @click="internPage"
              >
                {{ texts.historico_produto }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-else>
          <v-col cols="12" class="d-flex justify-center align-center pt-7 pb-4">
            <v-img
              contain
              max-width="153"
              max-height="180"
              src="@/assets/images/man-time-phone.svg"
            />
          </v-col>
          <v-col cols="12">
            <h2
              class="primary--text text-h5 font-weight-medium text-center"
              style="font-size: 26px; line-height: 28px; padding: 0 4vw"
            >
              {{ texts.dados_disponiveis_breve }}
            </h2>
          </v-col>
          <v-col cols="12">
            <InfoCard
              :text="this.texts.dados_disponiveis_em"
              :extraText="infoTextDate()"
              class="mb-3"
            />
          </v-col>
          <v-col cols="12">
            <h2
              class="secondary--text font-weight-medium"
              style="font-size: 22px; line-height: 22px"
            >
              {{ texts.rastreabilidade_da_producao }}
            </h2>
          </v-col>
          <v-row class="px-3">
            <v-col cols="7">
              <span class="label-text">{{ texts.industria }}</span>
              <p class="data-text">{{ trackOrigem.industry.name }}</p>
            </v-col>
            <v-col cols="5" class="d-flex justify-end">
              <v-img
                :src="trackOrigem.industry.logo"
                contain
                max-height="50"
                max-width="100"
              />
            </v-col>
            <v-col cols="12">
              <hr class="separator" />
            </v-col>
            <v-col cols="12">
              <span class="label-text">{{ texts.dt_producao }}</span>
              <p class="data-text">{{ dateProduction | formatDate }}</p>
            </v-col>
          </v-row>

          <v-col cols="12">
            <v-btn
              width="100%"
              height="59"
              dark
              color="secondary"
              @click="goSearchPage()"
              class="mt-3"
            >
              Buscar outro produto
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </div>
    <SearchPage v-else />
  </div>
</template>

<script>
import moment from 'moment'

import InfoCard from '@/components/cards/InfoTextCard'
import SearchPage from '@/components/SearchPage'

import ParallelogramButton from './ParallelogramButton.vue'

// import { goChat as $goChat } from '@/utils/chat'

export default {
  components: { InfoCard, ParallelogramButton, SearchPage },

  props: [
    'isHome',
    'texts',
    'track',
    'trackDestino',
    'trackOrigem',
    'industry'
  ],

  data() {
    return {
      isProgrammatic: false,
      infoDate: null,
      haveProduct: false,
      dateProduction: null,
      showSearchPage: false
    }
  },

  created() {
    this.$store.state.headerTitle = this.texts.dados_produto
  },

  mounted() {
    const todayDate = moment().format('YYYY-MM-DD')
    this.dateProduction = moment(this.$route.query.dp).format('YYYY-MM-DD')

    if (!this.trackOrigem || !this.trackOrigem.data) {
      this.checkDateHigher(todayDate, this.dateProduction)
    }

    if (this.isProgrammatic == false && !this.trackOrigem.environmental) {
      this.showSearchPage = true
      return
    }

    if (this.trackOrigem && this.trackOrigem.product) {
      this.haveProduct = true
      this.$store.state.menu_logo = false
    } else {
      this.haveProduct = false
      this.$store.state.menu_logo = true
    }

    window.scrollTo(0, 0)
  },

  methods: {
    goChat() {
      return (window.location = 'https://wa.me/message/DLRMYEEVN3PFL1')
    },

    getDueDate() {
      return moment(this.track.production_date)
        .add('days', this.trackOrigem.product.due_days)
        .format('YYYY-MM-DD')
    },

    infoTextDate() {
      let text = moment(this.track.production_date)
        .add(1, 'days')
        .format('DD/MM/YYYY')
        .toString()
      return text
    },

    checkDateHigher(date1, date2) {
      if (moment(date2).isSameOrAfter(moment(date1))) {
        this.$store.state.menu_logo = true
        this.isProgrammatic = true
      } else {
        this.$store.state.menu_logo = false
        this.isProgrammatic = false
      }
    },

    internPage() {
      this.$store.state.home = false
      this.$store.state.history = true
      this.$store.state.prevNavigation = 'home'
      this.$store.state.headerTitle = this.texts.historico_produto
    },

    seloEcotracePage() {
      this.$store.state.home = false
      this.$store.state.seloEcotrace = true
      this.$store.state.prevNavigation = 'home'
      this.$store.state.headerTitle = this.texts.selo_ecotrace
    },

    getProductName(product) {
      if (product.languages) {
        for (let i = 0; i < product.languages.length; i++) {
          let lg = product.languages[i]
          if (lg.language == this.texts.globalLang) return lg.name
        }
      }

      return product.name
    },

    goSearchPage() {
      window.location.href = '/'
    }
  }
}
</script>

<style lang="scss" scoped>
.product-name {
  font-size: 24px !important;
}

.prop-display {
  display: flex;
  font-family: 'Poppins', sans-serif !important;
  flex-direction: column;
  padding: 0;

  > label {
    color: #2b2b2b;
    line-height: normal;
    margin: 0 0 4px 0;
  }

  .text {
    line-height: 24px;

    &.--primary {
      color: #744600;
      font-weight: 700;
    }

    &.--secondary {
      color: #f1bb00;
      font-weight: 500;
    }

    &.--brown {
      color: #744600;
    }

    &.--bold {
      font-weight: 700;
    }
  }
}

.barcellos-card {
  $fontFamily: 'Source Sans Pro' !important;

  background-color: #f9f9f7;
  border-radius: 0;
  box-shadow: none !important;

  padding-bottom: 24px;

  .product-title {
    text-transform: uppercase;
    padding: 16px 0 8px 0;

    > h1 {
      color: #2b2b2b;
      font-family: $fontFamily;
      font-weight: 900;
      font-size: 24px;
      text-align: center;
      line-height: 28px;
      width: 100%;
    }
  }

  .product {
    align-items: center;
    background-image: url('../../assets/barcellos/barcellos-bg.jpeg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 280px;
    justify-content: flex-end;
    margin: 0 -24px;

    > .wrapper {
      background-color: white;
      border-radius: 50%;
      border: 2px solid white;
      box-shadow:
        rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      height: 160px;
      margin: 0 16px 0 0;
      overflow: hidden;
      width: 160px;

      > .display {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }
  }

  .contact {
    align-items: center;
    background-color: #eaeaea;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 16px 16px 24px;
    margin: 0 -24px;

    > .brand-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 0 8px 0;
      width: 100%;

      > .brand {
        height: 100%;
        object-fit: contain;
        object-position: center;
        width: auto;
      }
    }

    > .cta-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 32px);

      > .title {
        color: #2b2b2b;
        font-family: $fontFamily;
        font-size: 16px !important;
        font-weight: 900;
        line-height: normal;
        text-align: center;
        margin: 0 0 8px 0;
      }
    }

    .parallelogram-button {
      width: 100%;
    }

    @media screen and (min-width: 375px) {
      flex-direction: row;

      > .cta-wrapper {
        width: 64%;
      }

      > .brand-wrapper {
        margin: 0;
        width: 32%;
      }

      .parallelogram-button {
        width: fit-content;
      }
    }
  }

  .prop-display-wrapper {
    align-items: center;
    margin: 0 0 24px 0;
    &:last-of-type {
      margin: 0;
    }
  }

  .industry {
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 24px 0 0;

    .col:not(.prop-display) {
      flex-grow: 0;
      height: fit-content;
      padding: 0;
      margin: 0 0 0 16px;
      width: 32%;
    }
  }

  .dates {
    padding: 24px 0 0 0;
  }

  .btn-historico-produto {
    background-image: url('../../assets/barcellos/button-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: $fontFamily;
    font-weight: 700;
    font-size: 20px !important;
  }

  #btn_selo_ecotrace {
    width: 110px;
    height: 95px;
    box-shadow: none;
  }

  .btn-product {
    max-width: 55px;
    max-height: 55px;
    box-shadow: 0px 3px 15px #0000001a;
  }

  hr {
    border: 1px solid $tertiary-color;
  }

  .infos-container {
    padding: 0.5rem 0;

    label {
      display: block;
      font-size: 18px;
    }
    span {
      font-size: 18px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  .btn-historico-produto {
    border-radius: 16px;
    box-shadow: 0px 17px 31px #00000024;
    font-family: 'Poppins', sans-serif !important;
    font-size: 18px !important;
    letter-spacing: 1px;
  }

  .label-text {
    font-size: 18px;
    line-height: 22px;
    color: #6a707e;
  }

  .data-text {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: $secondary-color;
    margin: 0;
  }

  .separator {
    border: 0;
    border-bottom: 1px solid #f2f3f7;
  }

  .brand-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 20px 0;
    background-color: #f4f8f7;
    overflow: hidden;
    box-shadow: 0px 3px 0px #0000000a;
  }
}
</style>
