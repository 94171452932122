export default {
  inicio: '首页>',
  origem: '原产地',
  industria: '产业领域',
  dt_producao: '生产日期',
  dt_validade: '到期日',
  dt_recebimento: '收到日期',
  codigo_rastreio: '追踪码',
  tipo_corte: '切割方式',
  lote_producao: '生产批次',
  nome: '名称',
  cnpj: '公司税号',
  email: '电邮',
  telefone: '电话',
  fazenda: '农场',
  cidade: '城市',
  historico_produto: '历史沿革',
  dados_produto: '资料',
  info_origem: '产地信息 - 生物群落',
  ver_mapa: '在地图上查看',
  analise_ambiental: '环境分析',
  certificados: '证明书',
  blockchain: '区块链',
  contato: '我的帐号',
  selo_ecotrace: 'Ecotrace密封',
  info_industria: '行业资讯',
  dados_unidade_produtora: '生产单位数据',
  localizacao_unidade_produtora: '生产单元的位置',
  certificados_unidade_produtora: '生产单位证书',
  planta_habilitada_exportacao: '出口工厂',
  ver_lista_completa: '查看完整清单',
  ver_lista_reduzida: '查看候选清单',
  info_varejo: '虚拟助手',
  botao_chatbot: '您想和我们的虚拟助手通话吗？',
  localizacao_varejo: '零售地点',
  ajuda_sobre_produto: '需要这个产品的帮助吗？',
  fale_com_barcellos: '与Barcellos联系',
  fazer_pergunta: '提出问题',
  propriedade_conforme: '物业100％兼容！',
  nada_consta: '没事',
  certificados_title: 'SIF-机构注册',
  certificados_text:
    '对于所有动物源性产品行业，必须向联邦检验局（SIF）进行企业注册：肉类和副产品，牛奶和副产品，鱼，蜂蜜和鸡蛋；打算在州或联邦边界以外销售其产品的人。',
  blockchain_title: '区块链保护数据',
  blockchain_text:
    '区块链是一种分布式注册技术，旨在将权力下放作为一种安全措施。这些是分布式和共享的数据库和记录，具有为给定市场中发生的所有交易创建全局索引的功能。',
  seloecotrace_title: '区块链保护数据',
  seloecotrace_text:
    '区块链是一种分布式注册技术，旨在将权力下放作为一种安全措施。这些是分布式和共享的数据库和记录，具有为给定市场中发生的所有交易创建全局索引的功能。',
  deixe_mensagem: '留言',
  mensagem_header_text:
    '如果您发现此产品有任何问题或有任何疑问，请告诉我们。我们将尽快答复。',
  enviar_minha_duvida: '提交我的问题',
  produto_rastreabilidade: '具有生产可追溯性的产品',
  consulta_dados_historico_ecotrace:
    '现在，您可以使用Technology查询该产品生产的所有历史数据。',
  dados_disponiveis_breve: '数据将很快可用',
  rastreabilidade_da_producao: '生产可追溯性',
  dados_disponiveis_em: '该产品的数据正在处理中，将在以下位置提供',
  produto_frigol: '产品Frigol',

  propriedade_nao_conforme: '物業不適合',
  analise_status_conforme: '根據',
  analise_status_nao_conforme: '不符合',
  propriedade_com_inconsistencia: '財產不一致！',

  id_monitoramento: '監控ID',
  analise_ambiental_title: '環境分析',
  empresa_responsavel_monitoramento: '負責監控的公司',

  Desmatamento: '記錄中',
  'Trabalho escravo': '奴隸制',
  'Invasão de Terras Indígenas': '入侵土著土地',
  'Embargo IBAMA': '禁運巴西環境與可再生自然資源研究所',
  'Invasão de Unidade de Conservação Ambiental': '環境保護部門的入侵',

  nao_encontrado_title: '我們需要一些信息',
  informacao_nao_econtrada: '找不到信息',
  ajuda: '如果您需要幫助，請通過電子郵件聯繫',
  como_acessar: '要訪問所有產品信息，請告知包裝上顯示的數據',
  ver_dados_do_produto: '查看產品數據',
  languageSelector: '语言选择',
  lingua_pt_br: '葡萄牙语',
  lingua_en: '英语',
  lingua_es: '西班牙语',
  lingua_cn: '中文'
}
