<template>
  <div id="search-page">
    <v-card class="Ecotrace-card">
      <v-card-text>
        <v-col cols="12" class="d-flex justify-center align-center pt-7 pb-4">
          <v-img
            src="@/assets/images/woman-phone.svg"
            max-width="153"
            max-height="180"
            contain
          ></v-img>
        </v-col>
        <v-col cols="12">
          <h2
            class="primary--text text-h5 font-weight-medium text-center"
            style="font-size: 26px; line-height: 28px;"
          >
            {{ texts.nao_encontrado_title }}
          </h2>
        </v-col>
        <v-col cols="12">
          <InfoCard :text="texts.como_acessar" class="mb-3" />
        </v-col>
        <v-col v-if="errorMsg" cols="12">
          <v-alert dense text type="error">
            {{ texts.informacao_nao_econtrada }}.<br />
            {{ texts.ajuda }}
            <strong>contato@ecotrace.info</strong>
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="formData.sif"
            label="SIF"
            outlined
            append-icon="fas fa-barcode"
            @input="checkSendButton"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-menu
            v-model="dateDialog"
            :close-on-content-click="false"
            :nudge-right="0"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                :label="texts.dt_producao"
                append-icon="far fa-calendar-alt"
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.dp"
              @change="formatDate(formData.dp)"
              @input="dateDialog = false"
              color="primary"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="formData.lp"
            :label="texts.lote_producao"
            outlined
            append-icon="fas fa-barcode"
            @input="checkSendButton"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            :disabled="btnDisabled"
            width="100%"
            height="59"
            dark
            color="secondary"
            @click="doSearch"
          >
            {{ texts.ver_dados_do_produto }}
          </v-btn>
        </v-col>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

import InfoCard from '@/components/cards/InfoTextCard'

import langPTBR from '@/templates/ecotrace/pt-br.ts'
import langCN from '@/templates/ecotrace/cn.ts'

export default {
  components: { InfoCard },

  data() {
    return {
      texts: {},
      dateFormatted: '',
      formData: {
        sif: null,
        dp: new Date().toISOString().substr(0, 10),
        lp: null
      },
      errorMsg: false,
      dateDialog: false,
      btnDisabled: true
    }
  },

  mounted() {
    this.$store.state.menu_logo = true
    this.$store.state.prevNavigation = 'home'

    if (Object.keys(this.$route.query).length !== 0) {
      this.errorMsg = true
    } else {
      this.errorMsg = false
    }

    if (this.$route.query.lg) {
      switch (this.$route.query.lg) {
        case 'pt-br':
        case 'pt':
          this.texts = langPTBR
          break
        case 'cn':
        case 'zh-cn':
        case 'zhHans':
          this.texts = langCN
          break
        default:
          this.texts = langPTBR
          break
      }
    } else {
      this.texts = langPTBR
    }
  },

  methods: {
    formatDate(date) {
      this.dateFormatted = moment(date).format('DD/MM/YYYY')
    },

    checkSendButton() {
      if (
        this.formData.sif == null ||
        this.formData.sif == '' ||
        this.formData.lp == null ||
        this.formData.lp == ''
      ) {
        this.btnDisabled = true
      } else {
        this.btnDisabled = false
      }
    },

    doSearch() {
      this.formData.dp = this.formData.dp.replace(/-/g, '')
      window.location.href = `?sif=${this.formData.sif}&dp=${this.formData.dp}&lp=${this.formData.lp}`
    }
  }
}
</script>

<style lang="scss" scoped>
#search-page::v-deep {
  min-height: 100vh;
  overflow: hidden;

  .Ecotrace-card {
    .v-input__slot {
      fieldset {
        border: 2px solid #e0e2e8;
      }

      .v-label--active {
        color: $primary-color;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-top: -2px;
      }

      input,
      .theme--light.v-label {
        color: $secondary-color;
      }

      .theme--light.v-label {
        font-weight: 500;
      }

      .v-icon {
        font-size: 21px;
        color: #8f8f8f;
      }

      .v-text-field__slot {
        .v-label--active {
          color: $primary-color;
          background-color: #f9f9f9;
          padding: 0 2px;
          padding-right: 3px;
          margin-top: -2px;
        }
      }
    }

    .v-text-field__details {
      display: none;
    }

    .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $secondary-color !important;
      opacity: 0.5 !important;
    }
  }
}
</style>
